export const SWAP_FORM_FIELDS = {
  from: "from",
  to: "to",
};

export const SWAP_CREATE_TOKEN_FIELDS = {
  tokenName: "tokenName",
  symbol: "symbol",
  address: "address",
  icon: "icon",
};
