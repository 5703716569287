import React from "react";

const SmartInvest = () => {
  return (
    <div className="2xl:px-32 xl:px-24 lg:px-16 md:px-8 px-3 flex flex-col">
      <div className="2xl:text-4xl xl:text-3xl lg:text-2xl text-xl font-bold 2xl:mb-8 xl:mb-6 lg:mb-4 mb-2">
        Smart invest
      </div>
      <div className="flex flex-col gap-3 2xl:mb-16 xl:mb-12 lg:mb-9 md:mb-6 mb-3">
        <div className="2xl:text-2xl xl:text-xl lg:text-lg text-base">
          Crypto bots Premium Tier Game
        </div>
        <div className="text-gray-500 font-medium xl:text-lg text-base">
          With all assets reward pool and stake insured by pixple.
        </div>
      </div>
      <div className="2xl:text-2xl xl:text-xl lg:text-lg text-base mb-4">Alfrediux studios</div>
      <div className="flex flex-col sm:flex-row gap-8">
        <div className="text-gray-500 font-medium xl:text-lg text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mollis placerat nunc at
          mollis. Aliquam porta malesuada imperdiet. Etiam malesuada finibus ipsum, quis porttitor
          nulla. Ut magna augue, interdum at sem at, sodales volutpat libero. Nullam sed dui et erat
          congue ullamcorper. Quisque egestas dolor eu odio sagittis, eu tincidunt leo feugiat. Duis
          posuere urna non tempor consequat. Nunc tellus libero, pulvinar eu elit id, luctus
          fermentum nisi. Vestibulum fringilla justo molestie, hendrerit ex sed, bibendum nulla.
          Phasellus hendrerit nulla in enim imperdiet posuere. Phasellus at tellus at lorem dapibus
          porttitor.
        </div>
        <div className="text-gray-500 font-medium xl:text-lg text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mollis placerat nunc at
          mollis. Aliquam porta malesuada imperdiet. Etiam malesuada finibus ipsum, quis porttitor
          nulla. Ut magna augue, interdum at sem at, sodales volutpat libero. Nullam sed dui et erat
          congue ullamcorper. Quisque egestas dolor eu odio sagittis, eu tincidunt leo feugiat. Duis
          posuere urna non tempor consequat. Nunc tellus libero, pulvinar eu elit id, luctus
          fermentum nisi. Vestibulum fringilla justo molestie, hendrerit ex sed, bibendum nulla.
          Phasellus hendrerit nulla in enim imperdiet posuere. Phasellus at tellus at lorem dapibus
          porttitor.
        </div>
      </div>
    </div>
  );
};

export default SmartInvest;
