import React, { createContext, useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ethers } from "ethers";
import { Axios } from "../utils/axios";
export const AppContext = createContext();
import { message } from "antd";
import useWallet from "../hooks/useWallet";

const AppContextProvider = ({ children }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectStaking, setSelectStaking] = useState(0);
  const [buyCryptoState, setBuyCryptoState] = useState(2);
  const [p2PState, setP2PState] = useState(0);
  const [depositState, setDepositState] = useState(0);
  const [orderBuyState, setOrderBuyState] = useState(0);
  const [orderSellState, setOrderSellState] = useState(0);
  const [developerHeader, setDeveloperHeader] = useState(-1);
  const [playerHeader, setPlayerHeader] = useState(-1);
  const [player, setPlayer] = useState(true);
  const [owner, setOwner] = useState(false);
  const [limitOrdersList, setlimitOrdersList] = useState([]);
  const chain = localStorage.getItem("blockChain");
  const [blockChain, setBlockChain] = useState(chain ? chain : "CONCORDIUM");
  const [owneraccout, setowneraccount] = useState("12323213213213213213312");
  const _data = localStorage.getItem("fireblocks");
  const decrpt = atob(_data);
  const fireBlocks = _data ? JSON.parse(decrpt) : null;
  const [fireBlocksAccount, setFireBlockAccount] = useState(fireBlocks);
  const [fireBlockWallet, setFireBlockWallet] = useState();
  const { setWalletAddress } = useWallet();

  useEffect(() => {
    localStorage.setItem("blockChain", blockChain);
  }, [blockChain]);

  const getEthersInstance = async networkId => {
    let ethersProvider;

    if (window.ethereum) {
      ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
    } else {
      // const publicEndpoint = "https://data-seed-prebsc-1-s1.binance.org:8545/";
      // const publicEndpoint = "https://zetachain-evm.blockpi.network/v1/rpc/public"; //For Mainnet Only
      const publicEndpoint = "https://polygon-amoy.blockpi.network/v1/rpc/public";
      ethersProvider = new ethers.providers.JsonRpcProvider(publicEndpoint);
    }

    const currentNetworkId = await ethersProvider.getNetwork().then(network => network.chainId);

    if (currentNetworkId !== networkId && window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${networkId.toString(16)}` }],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: `0x${networkId.toString(16)}`,
                  chainName: "Polygon Amoy Testnet",
                  // chainName: "Binance Smart Chain Testnet",
                  nativeCurrency: {
                    // name: "Binance Smart Chain Testnet",
                    name: "Polygon Amoy Testnet",
                    // name: "ZetaChain Mainnet", //For Mainnet Only
                    symbol: "MATIC",
                    // symbol: "tBNB",
                    decimals: 18,
                  },
                  rpcUrls: [
                    "https://polygon-amoy.blockpi.network/v1/rpc/public",
                    "https://rpc-amoy.polygon.technology",
                  ],
                  // rpcUrls: ["https://data-seed-prebsc-1-s1.bnbchain.org:8545"],
                  // rpcUrls: ["https://zetachain-evm.blockpi.network/v1/rpc/public"], //For Mainnet Only
                },
              ],
            });
          } catch (addError) {
            // Handle "add" error
          }
        }
        // Handle other "switch" errors
      }
    }

    return ethersProvider;
  };

  const getWalletFunction = () => {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(accs => setWalletAddress(accs[0]))
        .catch(err => {
          console.error(err);
        });
    } else {
      message.error("Install metamask extension!!");
    }
  };

  const fireBlockAccountSignUp = async (name, chain) => {
    await Axios.post("/dexTesting/createVaultAccount", {
      name: name,
      assetId: chain,
    })
      .then(res => {
        const data = res.data.Responce;
        setFireBlockWallet(data.fireblocks_account_address);
      })
      .catch(e => {
        console.error("Message", e);
      });
  };

  const fireBlocksAccountLogin = async id => {
    await Axios.get(`/dexTesting/getVaultAccountByAddress/${id}`)
      .then(res => {
        const d = res.data;
        setFireBlockAccount(d);
        const str = JSON.stringify(d);
        const encrpt = btoa(str);
        localStorage.setItem("fireblocks", encrpt);
      })
      .catch(e => {
        console.error("MESSAGE", e);
      });
  };

  return (
    <AppContext.Provider
      value={{
        selectedIndex,
        selectStaking,
        setSelectedIndex,
        setSelectStaking,
        buyCryptoState,
        setBuyCryptoState,
        p2PState,
        setP2PState,
        depositState,
        setDepositState,
        orderBuyState,
        setOrderBuyState,
        orderSellState,
        setOrderSellState,
        developerHeader,
        playerHeader,
        setDeveloperHeader,
        setPlayerHeader,
        owner,
        setOwner,
        player,
        setPlayer,
        limitOrdersList,
        setlimitOrdersList,
        owneraccout,
        setowneraccount,
        blockChain,
        setBlockChain,
        getWalletFunction,
        getEthersInstance,
        fireBlocksAccount,
        fireBlocksAccountLogin,
        fireBlockAccountSignUp,
        fireBlockWallet,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.object,
};

export default AppContextProvider;
export const useAppContext = () => useContext(AppContext);
