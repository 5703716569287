import { useFormContext, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Components
import { MainButton } from "../../../components/Button/MainButton";
import TokenSelectInput from "../../../components/TokenSelectInput/TokenSelectInput";

// Utils
import { capitalizeString } from "../../../utils/common";
import { changeSwapDirection, getMaxCcdAmount } from "./utils";

// Hooks
import { useSwapDataUpdate } from "./hooks";

// Actions
import {
  setIsSwapModalOpen,
  setSwapTokenFrom,
  setSwapTokenTo,
} from "../../../store/reducers/SwapMaster/swapSlice";

import SlippageModal from "../../../pages/SwapMaster/Swap/SlippageModal.js";
// Icons
import { SwapDirectionIcon } from "../icons/SwapDirectionIcon";

// Constants
import { useState } from "react";
import { SWAP_FORM_FIELDS } from "./constants";

const SwapForm = () => {
  const dispatch = useDispatch();
  const balanceFrom = useSelector(s => s.swap.balance.from);
  const tokenFrom = useSelector(s => s.swap.tokenFrom);
  const tokenTo = useSelector(s => s.swap.tokenTo);
  const balanceTo = useSelector(s => s.swap.balance.to);
  const isNoFilledPools = useSelector(s => s.swapMaster.isNoFilledPools);
  const slippagepercentage = useSelector(s => s.swap.slippage);
  const [slippage, setSlippage] = useState(false);
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
  } = useFormContext();
  const watchedValues = useWatch();
  const currentValues = getValues();
  const { tokenList, fromPerToAmount, toPerFromAmount } = useSwapDataUpdate();

  const isFormEmpty = () => Object.values(watchedValues).every(x => !x);

  const onSubmit = handleSubmit(values => {
    if (Object.keys(values).length === 0) {
      // No form fields are filled, set error message
      setValue(SWAP_FORM_FIELDS.from, balanceFrom, { shouldValidate: true, shouldTouch: true });
      setValue(SWAP_FORM_FIELDS.to, balanceTo, { shouldValidate: true, shouldTouch: true });

      return;
    }

    dispatch(
      setIsSwapModalOpen({
        modal: "confirm",
        isOpen: true,
        modalData: { values, fromPerToAmount, toPerFromAmount },
      }),
    );
  });

  function handleSlippage() {
    setSlippage(prev => !prev);
  }

  const handleSwapDirection = () => {
    dispatch(changeSwapDirection());
  };

  const onMaxHandler = () => {
    const isCCD = !tokenFrom.contractName;
    const balance = isCCD ? getMaxCcdAmount(balanceFrom) : balanceFrom;
    setValue(SWAP_FORM_FIELDS.from, balance, { shouldValidate: true, shouldTouch: true });
  };

  const onMaxToHandler = () => {
    const isCCD = !tokenTo.contractName;
    const balance = isCCD ? getMaxCcdAmount(balanceTo) : balanceTo;
    setValue(SWAP_FORM_FIELDS.to, balance, { shouldValidate: true, shouldTouch: true });
  };

  const errorMessage = Object.values(errors)
    .map(({ message }) => message)
    .join(", ");

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`flex flex-col rounded-md w-full 2xl:w-155 bg-app-black sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] ${
          isNoFilledPools ? "relative pointer-events-none" : ""
        }`}
      >
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">From</div>
          <div className="font-normal text-gray-400">Balance: {balanceFrom}</div>
        </div>
        <TokenSelectInput
          name={SWAP_FORM_FIELDS.from}
          tokenList={tokenList}
          selectedToken={tokenFrom}
          disabledToken={tokenTo}
          backgroundColor="bg-app-black-button"
          onTokenSelect={tokenData => {
            dispatch(setSwapTokenFrom(tokenData));
          }}
          isWithMaxButton
          onMaxHandler={onMaxHandler}
          isAddToken
        />
        <div className="flex justify-center w-full mt-5">
          <div
            className="flex items-center justify-center rounded-full cursor-pointer full bg-app-black-button hover:bg-[#717A8B]"
            style={{ marginBottom: "10px", width: "53px", height: "53px" }}
            onClick={handleSwapDirection}
            title="Revert swap direction"
          >
            <SwapDirectionIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">To</div>
          <div className="font-normal text-gray-400">Balance: {balanceTo}</div>
        </div>
        {/* <TokenSelectInput
        name={SWAP_FORM_FIELDS.to}
        tokenList={tokenList}
        selectedToken={tokenTo}
        disabledToken={tokenFrom}
        backgroundColor="bg-app-black-button"
        onInput={onInputTokenPair}
        isWithMaxButton={!isUnstakeMode}
        onMaxHandler={onMaxToHandler}
        onTokenSelect={tokenData => {
          dispatch(setLiquidityTokenTo(tokenData));
        }}
        readOnly={isUnstakeMode}
        isSelectDisabled={isUnstakeMode}
        isAddToken={isCreateMode}
      /> */}
        <TokenSelectInput
          readOnly
          name={SWAP_FORM_FIELDS.to}
          tokenList={tokenList}
          selectedToken={tokenTo}
          disabledToken={tokenFrom}
          backgroundColor="bg-app-black-button"
          // readOnly
          onTokenSelect={tokenData => {
            dispatch(setSwapTokenTo(tokenData));
          }}
          isAddToken
          onMaxHandler={onMaxToHandler}
          // isWithMaxButton={!!currentValues?.from}
        />
        <div className="flex flex-col gap-2 mt-5 text-sm border-b-2 border-app-black">
          <div className="flex flex-row justify-between">
            <div>Price</div>
            <div>
              1 {tokenFrom.symbol?.slice(0, 15)} = {toPerFromAmount} {tokenTo.symbol?.slice(0, 15)}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div>Inverse Price</div>
            <div>
              1 {tokenTo.symbol?.slice(0, 15)} = {fromPerToAmount} {tokenFrom.symbol?.slice(0, 15)}
            </div>
          </div>
          <div className="flex justify-between">
            <div>Pool Fee</div>
            <div>0.01%</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>You will receive</div>
            <div className="text-2xl text-app-blue">
              {(parseFloat(currentValues[SWAP_FORM_FIELDS.to]) * (1 - 0.0001)).toFixed(8)}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <div>You will receive minimum</div>
            <div className="text-2xl text-app-blue">
              {" "}
              {(
                parseFloat(currentValues[SWAP_FORM_FIELDS.to]) * (1 - 0.0001) -
                parseFloat(currentValues[SWAP_FORM_FIELDS.to]) *
                  (1 - 0.0001) *
                  (slippagepercentage / 100)
              ).toFixed(8)}
            </div>
          </div>
        </div>
        {/* <div className="mt-2">
          <div className="text-sm text-slate-400">Please confirm conversion within the time.</div>
        </div>
        <div className="my-5 bg-white/10 rounded-md p-5">
          {" "}
          1 {tokenFrom.symbol?.slice(0, 15)} = {toPerFromAmount} {tokenTo.symbol?.slice(0, 15)}
        </div> */}
        {/* <div className="flex justify-between items-center text-[#717A8B] py-2">
          <p>Select Blockchain </p>
          <img src="/assets/images/mark.png" alt="icon" className="w-5 h-5" />
        </div> */}

        {/* <div className="flex flex-row justify-between">
          <div>Price Impact </div>
          <div>0.23%</div>
          </div>
        <div className="flex flex-row items-center justify-between mb-4">
          <div>Receive at least</div>
          <div className="text-2xl text-app-blue">28.65558 PIXP.USDT</div>
        </div>
        <div className="flex flex-row items-center justify-end mb-3 -my-2">
          <div className=" text-slate-500 text-sm">(Slippage 15%)</div>
        </div>
        <div className="flex flex-row items-center justify-between mb-4">
        <div>Estimated NRG Fee</div>
        <div className="text-2xl text-app-blue">80.35691 CCD</div>
      </div> */}
        <div className="flex  justify-between tems-center gap-4">
          {/* <Dropdown
            menu={{ items }}
            className="flex items-center justify-center p-4 h-16 w-64  rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
          >
            <a>
              <Space>
                {chain === "CONCORDIUM" ? (
                  <div>
                    <img src="/assets/images/Ellipse 157.png" alt="menu" />
                  </div>
                ) : (
                  <div>
                    <img src="/assets/images/poly.png" width={20} height={20} alt="menu" />
                  </div>
                )}
                {chain}
                <DownOutlined className="mb-1" />
              </Space>
            </a>
          </Dropdown> */}
          <MainButton
            disabled={slippage}
            onClick={() => handleSlippage()}
            className="p-4 h-16 w-full bg-[#37404C] text-lg disabled:bg-[#717A8B] hover:!bg-[#717A8B] "
          >
            Slippage Tolerance
          </MainButton>
        </div>
        <MainButton
          type="submit"
          disabled={isFormEmpty() || !!errorMessage || isSubmitting}
          className="p-4 h-16 mt-5 bg-app-blue text-lg disabled:bg-app-black-button disabled:hover:!bg-app-black-button hover:!bg-[#50D0FB] "
        >
          {errorMessage === "from must be a positive number"
            ? "From field amount should be greater than 0"
            : capitalizeString(errorMessage) || "Confirm"}
        </MainButton>
        {isNoFilledPools && (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-25 flex items-start justify-center">
            <p className="text-lg pt-4">No filled pools found</p>
          </div>
        )}
      </form>
      {slippage ? <SlippageModal closeModal={handleSlippage} /> : ""}
    </>
  );
};

export default SwapForm;
