import LiquidityConfirmModal from "./LiquidityConfirmModal";
import LiquidityForm from "./LiquidityForm";
import LiquidityMetaMask from "./LiquidityMetaMask";
import LiquidityCreateModal from "./LiquidityCreateModal";
import { useAppContext } from "../../../contexts/AppContext";
import LiquidityCreateModalMeta from "./LiquidityCreateModalMeta";

const LiquidityCard = props => {
  const { blockChain } = useAppContext();

  return (
    <>
      {blockChain === "POLYGON" ? <LiquidityMetaMask {...props} /> : <LiquidityForm {...props} />}
      {/* <LiquidityMetaMask {...props} /> */}
      <LiquidityConfirmModal />
      <LiquidityCreateModal />
      <LiquidityCreateModalMeta />
    </>
  );
};

export default LiquidityCard;
