// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg width=%2724%27 height=%2724%27 viewBox=%270 0 24 24%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z%27 stroke=%27%23717A8B%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3Cpath d=%27M20.9984 20.9999L16.6484 16.6499%27 stroke=%27%23717A8B%27 stroke-width=%272%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27/%3E%3C/svg%3E%0A", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter {
  display: flex;
  gap: 15px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.filter--offset-bottom {
  margin-bottom: 30px;
}
.filter__input {
  border-radius: 5px;
  background: #29313C;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  color: #717A8B;
  background-repeat: no-repeat;
  background-position-x: 25px;
  background-position-y: center;
  padding: 18px 25px 18px 59px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.filter__btn {
  color: #fff;
  background: #29313C;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 25px;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  margin-left: auto;
}
.filter__btn svg {
  margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/Filter/Filter.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;AACF;AAAE;EACE,mBAAA;AAEJ;AAAE;EACE,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;EACA,4BAAA;EACA,2BAAA;EACA,6BAAA;EACA,4BAAA;EACA,yDAAA;AAEJ;AACE;EACE,WAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,iBAAA;AACJ;AAEE;EACE,kBAAA;AAAJ","sourcesContent":[".filter {\n  display: flex;\n  gap: 15px;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n  &--offset-bottom {\n    margin-bottom: 30px;\n  }\n  &__input {\n    border-radius: 5px;\n    background: #29313C;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 24px;\n    width: 100%;\n    color: #717A8B;\n    background-repeat: no-repeat;\n    background-position-x: 25px;\n    background-position-y: center;\n    padding: 18px 25px 18px 25px + 24px + 10px;\n    background-image: url(\"data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z' stroke='%23717A8B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.9984 20.9999L16.6484 16.6499' stroke='%23717A8B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A\");\n  }\n\n  &__btn {\n    color: #fff;\n    background: #29313C;\n    border-radius: 5px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 18px 25px;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    text-transform: uppercase;\n    margin-left: auto;\n  }\n\n  &__btn svg {\n    margin-right: 15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
