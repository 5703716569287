import Game1 from "../../asssets/images/market/nft1.jpg";
import Game2 from "../../asssets/images/market/ply.png";
export const tokenList = [
  {
    id: 1,
    title: "FIAT",
  },
  {
    id: 2,
    title: "TOKEN",
  },
  {
    id: 3,
    title: "COIN",
  },
];

export const tokencoinList = [
  {
    id: 1,
    title: "DEPOSIT",
  },
  {
    id: 2,
    title: "WITHDRAW",
  },
  {
    id: 3,
    title: "BUY",
  },
  {
    id: 1,
    title: "TRANSFER",
  },
  {
    id: 1,
    title: "SWAP",
  },
];

export const fiatList = [
  {
    id: 1,
    title: "DEPOSIT",
  },
  {
    id: 2,
    title: "WITHDRAW",
  },
];

export const tableList = [
  {
    id: 1,
    Coin: "BTC",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 2,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 3,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 4,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 5,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 6,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 7,
    Coin: "LUNA",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
];

export const tablefiatList = [
  {
    id: 1,
    Coin: "EURO",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 2,
    Coin: "USD",
    Total: "50 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 3,
    Coin: "USC",
    Total: "90 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 4,
    Coin: "FOUND",
    Total: "50 000",
    Available: "210 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 5,
    Coin: "FRAN",
    Total: "60 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 6,
    Coin: "DINAR",
    Total: "30 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
  {
    id: 7,
    Coin: "RMB",
    Total: "40 000",
    Available: "212 000",
    Orders: "34 000",
    PIXP: "3 000",
  },
];

export const stakingList = [
  {
    id: 1,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 2,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 3,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 4,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 5,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 6,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 7,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 8,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
  {
    id: 9,
    Game: "Crypto Bots",
    Coin: "PIXP",
    Rewards: "300 PIXP",
    StakedAmount: "3 000 PIXP",
    StartDate: "13-03-2022",
    ESTAPY: "10%",
    LockedDays: "120 days",
    InvestDate: "3%",
  },
];

export const transactionButtonList = [
  {
    id: 1,
    title: "CRYPTO",
  },
  {
    id: 2,
    title: "FIAT",
  },
  {
    id: 3,
    title: "TRANSFER",
  },
  {
    id: 4,
    title: "DISTRIBUTION",
  },
  {
    id: 5,
    title: "BNB CONVERT",
  },
];

export const dropdownList = [
  {
    id: 1,
    title: "Type",
    header: "Deposit",
    content: [
      {
        id: 1,
        title: "One",
      },
      {
        id: 2,
        title: "Two",
      },
      {
        id: 3,
        title: "Three",
      },
    ],
  },
  {
    id: 2,
    title: "Time",
    header: "Past 30 days",
    content: [
      {
        id: 1,
        title: "One",
      },
      {
        id: 2,
        title: "Two",
      },
      {
        id: 3,
        title: "Three",
      },
    ],
  },
  {
    id: 3,
    title: "Asset",
    header: "All",
    content: [
      {
        id: 1,
        title: "Eleven",
      },
      {
        id: 2,
        title: "Ten",
      },
      {
        id: 3,
        title: "Eight",
      },
    ],
  },
  {
    id: 4,
    title: "Status",
    header: "All",
    content: [
      {
        id: 1,
        title: "One",
      },
      {
        id: 2,
        title: "Two",
      },
      {
        id: 3,
        title: "Three",
      },
    ],
  },
];

export const paymentBankList = [
  {
    id: 1,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
  {
    id: 2,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
  {
    id: 3,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
  {
    id: 4,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
  {
    id: 5,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
  {
    id: 6,
    name: "Juan Vivas",
    bankAccount: "xxxxxxxxxxxxx",
    bankName: "HSBC",
  },
];

export const paymentCardList = [
  {
    id: 1,
    type: "VISA",
    Number: "4442xxxx9375",
  },
  {
    id: 2,
    type: "VISA",
    Number: "4442xxxx9375",
  },
  {
    id: 3,
    type: "VISA",
    Number: "4442xxxx9375",
  },
  {
    id: 4,
    type: "VISA",
    Number: "4442xxxx9375",
  },
  {
    id: 5,
    type: "VISA",
    Number: "4442xxxx9375",
  },
  {
    id: 6,
    type: "VISA",
    Number: "4442xxxx9375",
  },
];

export const gamingList = [
  {
    id: 1,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 2,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 3,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 4,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 5,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 6,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
  {
    id: 7,
    coin: "CPAN",
    game: "Crypto Bots",
    reward: "100 000",
    deposit: "50 000",
    available: "150 000",
    daw: "3 000",
  },
];

export const transactionList = [
  {
    id: 1,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 2,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 3,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 4,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 5,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 6,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 7,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 8,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
  {
    id: 9,
    time: "14:11:58",
    type: "Deposit",
    wallet: "212 000",
    asset: "All",
    amount: "3 000",
    TxID: "2kk23981as0",
    status: "Completed",
  },
];

export const NFTList = [
  {
    id: 1,
    game: Game1,
    quantity: "30",
    valuet: "300.000 PIXP",
    startDate: "01/02/2023",
    earning: "50.000 PIXP",
    expenses: "25.000 PIXP",
    gameNFTList: [
      {
        id: 1,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 2,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 3,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 4,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
    ],
  },
  {
    id: 2,
    game: Game1,
    quantity: 30,
    valuet: "300.000 PIXP",
    startDate: "01/02/2023",
    earning: "50.000 PIXP",
    expenses: "25.000 PIXP",
    gameNFTList: [
      {
        id: 1,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 2,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 3,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 4,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
    ],
  },
  {
    id: 3,
    game: Game2,
    quantity: 30,
    valuet: "300.000 PIXP",
    startDate: "01/02/2023",
    earning: "50.000 PIXP",
    expenses: "25.000 PIXP",
    gameNFTList: [
      {
        id: 1,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 2,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 3,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 4,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
    ],
  },
  {
    id: 4,
    game: Game2,
    quantity: 30,
    valuet: "300.000 PIXP",
    startDate: "01/02/2023",
    earning: "50.000 PIXP",
    expenses: "25.000 PIXP",
    gameNFTList: [
      {
        id: 1,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 2,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 3,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 4,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
    ],
  },
  {
    id: 5,
    game: Game1,
    quantity: 30,
    valuet: "300.000 PIXP",
    startDate: "01/02/2023",
    earning: "50.000 PIXP",
    expenses: "25.000 PIXP",
    gameNFTList: [
      {
        id: 1,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 2,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 3,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
      {
        id: 4,
        NFT: Game1,
        purchaseValue: "700 PIXP",
        purchaseDate: "30/03/2022",
        soldDate: "-----",
        earning: "170 PIXP",
        expenses: "50 PIXP",
      },
    ],
  },
];

export const accountList = [
  {
    id: 1,
    title: "From:",
    content: [
      {
        id: 1,
        title: "GAME ACCOUNT",
      },
      {
        id: 2,
        title: "START ACCOUNT",
      },
      {
        id: 3,
        title: "TRADE ACCOUNT",
      },
      {
        id: 4,
        title: "NFT ACCOUNT",
      },
      {
        id: 5,
        title: "STAKING ACCOUNT",
      },
    ],
  },
  {
    id: 2,
    title: "To:",
    content: [
      {
        id: 1,
        title: "GAME ACCOUNT",
      },
      {
        id: 2,
        title: "START ACCOUNT",
      },
      {
        id: 3,
        title: "TRADE ACCOUNT",
      },
      {
        id: 4,
        title: "NFT ACCOUNT",
      },
      {
        id: 5,
        title: "STAKING ACCOUNT",
      },
    ],
  },
];

export const coinList = [
  {
    id: 1,
    title: "Bitcoin",
  },
  {
    id: 2,
    title: "USDT",
  },
];

export const currencyList = [
  {
    id: 1,
    title: "USD American Dollar",
  },
  {
    id: 2,
    title: "CAD Canadian Dollar",
  },
  {
    id: 3,
    title: "FF France Frang",
  },
  {
    id: 4,
    title: "CHF Swiss Frang",
  },
  {
    id: 5,
    title: "Pound British Money",
  },
];
