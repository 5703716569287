import React, { useEffect } from "react";
import LimitCard from "./LimitCard";
import GraphCard from "./Graph/GraphCard";
import SwapModal from "./Swap/SwapModal";
import LimitOrders from "./LimitOrders";
import { useSelector } from "react-redux";
import LimitSuccessCard from "./LimitSuccess";
import { useAppContext } from "../../contexts/AppContext";
import { useNavigate } from "react-router-dom";
// import LimitCardMetamask from "./LimitCardMetamask";
// import LimitSuccessMetamask from "./LimitSuccessMetamask";
// import LimitOrdersMetamask from "./LimitOrdersMetamask";

const Limit = () => {
  const limitsuccessmodal = useSelector(s => s.swap.limitsuccessmodal);
  const { blockChain } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (blockChain === "METAMASK" || blockChain === "POLYGON") {
      navigate("/swap-master/swap");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChain]);

  return (
    <>
      {!limitsuccessmodal && (
        <div className="grid grid-cols-1 w-full gap-8 2xl:grid-cols-2 md:w-auto">
          <GraphCard />
          {blockChain === "METAMASK" || blockChain === "POLYGON" ? null : ( // <LimitCardMetamask />
            <LimitCard />
          )}
          <SwapModal />
          {blockChain === "METAMASK" || blockChain === "POLYGON" ? null : ( // <LimitOrdersMetamask />
            <LimitOrders />
          )}
        </div>
      )}
      {limitsuccessmodal && <>{limitsuccessmodal === "Metamask" ? null : <LimitSuccessCard />}</>}
    </>
  );
};

export default Limit;
