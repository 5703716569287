import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import ERC20 from "../../../ContractABI/ERC20.json";

// Constants
import { LIQUIDITY_ADD_TOKEN_FORM_ID } from "../Liquidity/constants";

// Components
import Loader from "../../../components/Loader/Loader";
import { MainButton } from "../../../components/Button/MainButton";
import Input from "../../../components/Input/Input";
import { LineIcon } from "../../../components/TokenSelectInput/icons";

// Utils
import { validationAddTokenSchemaSwap } from "../Liquidity/validationSchema";
import { capitalizeString, getModalOverflow } from "../../../utils/common";

// Hooks
import { useLiquidityInputsHandlers } from "../Liquidity/hooks";
// Actions
import { setIsSwapModalOpen } from "../../../store/reducers/SwapMaster/swapSlice";
import { SWAP_CREATE_TOKEN_FIELDS } from "./constants";
import { ethers } from "ethers";
import { message } from "antd";
import { createToken } from "../../../utils/polygonFirebase";

const SwapCreateTokenModal = () => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const isShowModal = useSelector(s => s.swap.modals.createToken.isOpen);
  const modalData = useSelector(s => s.swap.modals.createToken.modalData);
  const methods = useForm({
    defaultValues: {
      [SWAP_CREATE_TOKEN_FIELDS.tokenName]: "",
      [SWAP_CREATE_TOKEN_FIELDS.tokenSymbol]: "",
      [SWAP_CREATE_TOKEN_FIELDS.tokenAddress]: "",
    },
    resolver: yupResolver(validationAddTokenSchemaSwap),
    mode: "all",
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
    setValue,
  } = methods;
  const { onAddTokenInput } = useLiquidityInputsHandlers({
    setValue,
  });

  if (!isShowModal) return null;

  const closeModal = () => {
    dispatch(setIsSwapModalOpen({ modal: "createToken", isOpen: false }));
    setIsSuccess(false);
    reset();
    setIsProcessing(false);
  };

  const errorMessage = Object.values(errors)
    .map(({ message }) => message)
    .join(", ");

  const handeSetIsSuccess = () => {
    setIsSuccess(false);
    reset();
    dispatch(
      setIsSwapModalOpen({
        modal: "createToken",
        isOpen: false,
      }),
    );
  };

  const onSubmit = async data => {
    const { tokenName, symbol, address } = data;

    if (!address.startsWith("0x")) {
      message.error("Invalid Token address");
      throw new Error("Invalid Token address");
    }

    const request = {
      [SWAP_CREATE_TOKEN_FIELDS.tokenName]: tokenName,
      [SWAP_CREATE_TOKEN_FIELDS.symbol]: symbol,
      [SWAP_CREATE_TOKEN_FIELDS.address]: address,
      [SWAP_CREATE_TOKEN_FIELDS.icon]: `https://api.pixpel.io/api/v1/image/${tokenName}.png`,
    };

    try {
      setIsProcessing(true);
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = await provider.getSigner();
      const tokenContract = new ethers.Contract(address, ERC20, signer);
      await tokenContract.name();
      const response = await createToken(request);
      setIsSuccess(true);
      dispatch(
        setIsSwapModalOpen({
          modal: "createToken",
          isOpen: true,
          modalData: response.data,
        }),
      );
    } catch (err) {
      message.error("Invalid Token address");
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className={`fixed inset-0 z-10 ${getModalOverflow()}`}>
      <div className="fixed inset-0 w-full h-full bg-black opacity-40" onClick={closeModal} />
      <div className="flex items-center min-h-screen px-5 py-8">
        <div className="relative flex flex-col w-full sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] mx-auto md1:text-lg 2xs:text-sm bg-app-black-modal rounded-xl md1:w-125">
          <div className="flex mb-5">
            <div className="text-xl font-medium 2xs:text-2xl">
              {isSuccess ? "Success Added Token" : "Add Token"}
            </div>
          </div>
          <div className={"flex-auto bg-app-black-modal"}>
            {isSuccess ? (
              <>
                <div className="flex flex-col gap-1 px-3 py-2 rounded-md 2xs:py-5 2xs:px-7 bg-app-black mb-7">
                  <div className="flex flex-row gap-3 1xs:justify-between 2xs:w-auto">
                    <div className="flex 1xs:w-26 ">
                      <div className="text-xs font-medium 2xs:text-base text-slate-400">Token</div>
                    </div>
                    <div className="flex w-56">
                      <div className="text-xs font-medium 2xs:text-base">{modalData.symbol}</div>
                    </div>
                  </div>
                </div>
                <MainButton
                  disabled={isProcessing}
                  className="w-full flex items-center justify-center rounded-md cursor-pointer h-14 bg-app-green hover:bg-app-green disabled:cursor-wait"
                  onClick={handeSetIsSuccess}
                >
                  {isProcessing && <Loader size="md" />}
                  <div className="text-lg font-medium">Close</div>
                </MainButton>
              </>
            ) : (
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} id={LIQUIDITY_ADD_TOKEN_FORM_ID}>
                  <div className="flex flex-row items-center w-full h-16 rounded bg-app-black-button mb-8">
                    <div className="h-16 px-4 flex items-center">Name</div>
                    <div className="flex">
                      <LineIcon />
                    </div>
                    <div className={"flex-auto bg-app-black-button"}>
                      <Input
                        name={SWAP_CREATE_TOKEN_FIELDS.tokenName}
                        className={"w-full h-14 bg-app-black-button input-autofill"}
                        placeholder="Token 1"
                        onInput={onAddTokenInput}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full h-16 rounded bg-app-black-button mb-8">
                    <div className="h-16 px-5 flex items-center">Symbol</div>
                    <div className="flex">
                      <LineIcon />
                    </div>
                    <div className={"flex-auto bg-app-black-button"}>
                      <Input
                        name={SWAP_CREATE_TOKEN_FIELDS.symbol}
                        className={"w-full h-14 bg-app-black-button input-autofill"}
                        placeholder="TKN"
                        maxLength={5}
                        onInput={onAddTokenInput}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row items-center w-full h-16 rounded bg-app-black-button mb-8">
                    <div className="h-16 px-5 flex items-center">Address</div>
                    <div className="flex">
                      <LineIcon />
                    </div>
                    <div className={"flex-auto bg-app-black-button"}>
                      <Input
                        name={SWAP_CREATE_TOKEN_FIELDS.address}
                        className={"w-full h-14 bg-app-black-button input-autofill"}
                        placeholder="0x12345678...90abc"
                        onInput={onAddTokenInput}
                      />
                    </div>
                  </div>
                  <MainButton
                    type="submit"
                    disabled={!!errorMessage || isSubmitting}
                    className="w-full p-4 h-14 mt-5 bg-app-green hover:bg-app-green text-lg disabled:bg-app-black-button"
                    form={LIQUIDITY_ADD_TOKEN_FORM_ID}
                  >
                    {isProcessing && <Loader size="md" />}
                    {capitalizeString(errorMessage) || "Supply"}
                  </MainButton>
                </form>
              </FormProvider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwapCreateTokenModal;
