import { ethers } from "ethers";
import { useEffect, useState } from "react";
import PairABI from "../ContractABI/Pool.json";
import ERC20 from "../ContractABI/ERC20.json";
import { getPairs, getPairsData } from "../utils/polygonFirebase";
import useContracts from "./useContracts";
import useWallet from "./useWallet";

export default function usePairs() {
  const [pairs, setPairs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [providedLoading, setProvidedLoading] = useState(false);
  const { signer, walletAddress } = useWallet();
  const { contracts } = useContracts();

  const fetchPairsFromApi = async () => {
    try {
      setLoading(true);

      const { success, error, data: apiPairs } = await getPairs();

      if (!success) {
        setPairs([]);
        setLoading(false);
        console.error(error);

        return;
      }

      if (!walletAddress || !contracts.liquidityNFT) {
        setPairs(apiPairs);
        setLoading(false);

        return;
      }

      const newPairs = await Promise.all(
        apiPairs.map(async pair => {
          const contract = new ethers.Contract(pair.pairAddress, PairABI, signer);
          const tokenAContract = new ethers.Contract(pair.tokenA.address, ERC20, signer);
          const tokenBContract = new ethers.Contract(pair.tokenB.address, ERC20, signer);
          const tokensOwed0Eth = await tokenAContract.balanceOf(pair.pairAddress);
          const tokensOwed0 = await parseFloat(ethers.utils.formatEther(tokensOwed0Eth));
          const tokensOwed1Eth = await tokenBContract.balanceOf(pair.pairAddress);
          const tokensOwed1 = await parseFloat(ethers.utils.formatEther(tokensOwed1Eth));
          const liquidityEth = await contract.liquidity();
          const tickSpacing = await contract.tickSpacing();
          const liquidity = await parseFloat(ethers.utils.formatEther(liquidityEth));
          const newLiquidity = Number(liquidity).toLocaleString() === "0" ? 0 : liquidity;
          const { sqrtPriceX96, tick } = await contract.slot0();

          return {
            ...pair,
            contract,
            tokensOwed0,
            tokensOwed1,
            liquidity: newLiquidity,
            liquidityEth: liquidityEth || "0",
            tickSpacing,
            tick: tick,
            sqrtPriceX96,
            tokenA: {
              ...pair.tokenA,
              contract: tokenAContract,
            },
            tokenB: {
              ...pair.tokenB,
              contract: tokenBContract,
            },
          };
        }),
      );

      setPairs(newPairs);
      await fetchPairData(newPairs);
    } catch (error) {
      console.error("Error fetching pairs from API:", error);
      setLoading(false);
    }
  };

  const fetchPairData = async pairs => {
    if (!pairs.length) {
      setLoading(false);
      setPairs(pairs);

      return;
    }

    try {
      setProvidedLoading(true);

      const contract = contracts.liquidityNFT;
      const totalSupplyEth = await contract.totalSupply();
      const totalSupply = Number(totalSupplyEth);

      const updatedPairs = pairs.map(async pair => {
        const { data: pairsData } = await getPairsData();
        const pairData = pairsData.find(
          pairData =>
            pairData.pairAddress.toLowerCase() === pair.pairAddress.toLowerCase() &&
            pairData.owner.toLowerCase() === walletAddress.toLowerCase(),
        );

        if (!pairData?.tokenId) {
          return {
            ...pair,
            liquidityProvided: 0,
            tokenId: "",
            totalSupply,
            pairDataId: "",
            pairDataTokensOwed0: 0,
            pairDataTokensOwed1: 0,
          };
        }

        const {
          liquidity: liquidityEth,
          tokensOwed0: tokensOwed0Eth,
          tokensOwed1: tokensOwed1Eth,
        } = await contract.positions(pairData?.tokenId?.toString());
        const liquidity = await parseFloat(ethers.utils.formatEther(liquidityEth));
        const newLiquidity = Number(liquidity).toLocaleString() === "0" ? 0 : liquidity;
        const tokensOwed0 = await parseFloat(ethers.utils.formatEther(tokensOwed0Eth));
        const tokensOwed1 = await parseFloat(ethers.utils.formatEther(tokensOwed1Eth));

        return {
          ...pair,
          liquidityProvided: newLiquidity || 0,
          liquidityEth,
          tokenId: pairData?.tokenId || "",
          totalSupply,
          pairDataId: pairData?.id || "",
          pairDataTokensOwed0: tokensOwed0 || 0,
          pairDataTokensOwed1: tokensOwed1 || 0,
        };
      });

      const newPairs = await Promise.all(updatedPairs);

      setPairs(newPairs);
    } catch (error) {
      console.error("Error fetching pair data:", error);
    } finally {
      setLoading(false);
      setProvidedLoading(false);
    }
  };

  useEffect(() => {
    fetchPairsFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, contracts]);

  const refetchPairs = async () => {
    await fetchPairsFromApi();
  };

  return { pairs, loading, refetchPairs, providedLoading };
}
