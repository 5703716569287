import React, { useState, useEffect } from "react";

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = error => {
    console.error(error.message);
    setHasError(true);
  };

  useEffect(() => {
    const errorListener = event => {
      handleError(event.error);
    };

    window.addEventListener("error", errorListener);

    return () => {
      window.removeEventListener("error", errorListener);
    };
  }, []);

  if (hasError) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="bg-red-100 border border-red-400 !text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold !text-red-700">Oops!</strong>
          <span className="block sm:inline !text-red-700">
            {" "}
            Something went wrong. Please try again later.
          </span>
        </div>
      </div>
    );
  }

  return children;
};

export default ErrorBoundary;
