import { useDispatch, useSelector } from "react-redux";
import { MainButton } from "../../../components/Button/MainButton";
import { setSlippage } from "../../../store/reducers/SwapMaster/swapSlice";
import { useState } from "react";

const SlippageModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const slippage = useSelector(state => state.swap.slippage);
  const [customSlippage, setCustomSlippage] = useState("");
  const [isCustom, setIsCustom] = useState(!isNaN(slippage) && slippage > 1.5);
  const [isError, setIsError] = useState(false); // State to track if there is an error in custom slippage

  const handleSlippageChange = value => {
    dispatch(setSlippage(value));
    setIsCustom(false);
    setCustomSlippage("");
    setIsError(false);
  };

  const handleCustomSlippageChange = e => {
    const value = e.target.value;
    setCustomSlippage(value);
    dispatch(setSlippage(parseFloat(value)));

    if (parseFloat(value) >= 0.5) {
      setIsError(false);
    }
  };

  // const handleBlur = () => {
  //   let value = parseFloat(customSlippage);

  //   if (isNaN(value) || value < 0.5) {
  //     value = 0.5;
  //     setCustomSlippage(String(value));
  //     setIsError(true);
  //   }

  //   dispatch(setSlippage(value));
  // };

  const activateCustomMode = () => {
    setIsCustom(true);

    if (isNaN(slippage) || !customSlippage) {
      setCustomSlippage(slippage?.toString() || "0.5");
      dispatch(setSlippage(slippage || 0.5));
    }

    setIsError(false);
  };

  const handleConfirmClick = () => {
    if (isNaN(slippage) || slippage < 0.5) {
      setIsError(true); // Set error if the input value is less than 0.5 or not a number

      return; // Prevent modal close on error
    } else {
      closeModal(); // Close modal only if there's no error
    }
  };

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div
        className="fixed inset-0 w-full h-full bg-black opacity-40"
        onClick={handleConfirmClick}
      />
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative flex flex-col w-full sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] mx-auto text-lg bg-app-black-modal rounded-xl xs:w-auto">
          <div className="flex mb-5 justify-between">
            <div className="text-md font-medium text-center 2xs:text-2xl flex items-center justify-center w-full">
              <p>Slippage</p>
            </div>
            <button
              onClick={handleConfirmClick}
              className="bg-[#717a8b9d] py-3 px-5 rounded-lg hover:bg-slate-400"
            >
              x
            </button>
          </div>
          <div className="flex flex-col gap-1 py-2 rounded-md 2xs:py-5 2xs:px-7 mb-7">
            <div className="flex flex-col">
              <div className="flex justify-between mt-2">
                <div
                  className={`${
                    slippage === 0.5 ? "bg-app-blue" : "bg-app-black"
                  } m-1 py-4 w-1/3 lg:px-10  cursor-pointer rounded-md hover:bg-[#717a8b9d] flex items-center justify-center`}
                  onClick={() => handleSlippageChange(0.5)}
                >
                  0.5%
                </div>
                <div
                  className={`${
                    slippage === 1 ? "bg-app-blue" : "bg-app-black"
                  } m-1 py-4 w-1/3 lg:px-10 cursor-pointer rounded-md hover:bg-[#717a8b9d] flex items-center justify-center`}
                  onClick={() => handleSlippageChange(1)}
                >
                  1%
                </div>
                <div
                  className={`${
                    slippage === 1.5 ? "bg-app-blue" : "bg-app-black hover:bg-[#717a8b9d]"
                  } m-1 py-4 w-1/3 lg:px-10 cursor-pointer rounded-md flex items-center justify-center`}
                  onClick={() => handleSlippageChange(1.5)}
                >
                  1.5%
                </div>
              </div>
              {/* <div
                className={`${
                  isCustom ? "bg-blue-400" : "bg-app-black"
                } m-1 py-4 px-10 cursor-pointer rounded-md hover:bg-blue-400 text-center`}
                onClick={activateCustomMode}
              >
                Custom
              </div> */}
            </div>
            <div
              className={`bg-app-black mt-3 flex rounded-md mx-1 justify-between px-5 py-4 ${
                isError ? "border border-red-500" : ""
              }`}
              onMouseEnter={activateCustomMode}
            >
              <input
                value={customSlippage}
                onChange={handleCustomSlippageChange}
                // onBlur={handleBlur}
                type="number"
                placeholder={isNaN(slippage) ? "0" : slippage}
                className={`bg-transparent w-10/12 ${isError ? "text-red-500" : ""}`}
                disabled={!isCustom}
              />
              <p>%</p>
            </div>
            <h1 className={`text-xs pl-1 ${isError ? "text-red-500" : "text-gray-500"}`}>
              Slippage cannot be less than 0.5%
            </h1>
            <MainButton
              onClick={handleConfirmClick}
              className="p-4 h-16 mt-5 bg-app-green text-lg disabled:bg-app-black-button hover:!bg-[#1FF19F]"
            >
              Confirm
            </MainButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlippageModal;
