export const NFTList = {
  title1: "320.53 PIXP",
  title2: "320.53 PIXP",
};
export const StakingList = {
  title1: "857,320.53 PIXP",
  title2: "857,320.53 PIXP",
};
export const FiatList = {
  title1: "57,320.53 PIXP",
  title2: "57,320.53 PIXP",
};
export const SpotList = {
  title1: "7,320.53 PIXP",
  title2: "7,320.53 PIXP",
};
export const TokenList = {
  title1: "20.53 PIXP",
  title2: "20.53 PIXP",
};
