import { useEffect, useState, useCallback } from "react";
import useWallet from "./useWallet";
import { ethers } from "ethers";
import WrappedMatic from "../ContractABI/WrappedMatic.json";
import LiquidityNFT from "../ContractABI/LiquidityNFT.json";
import Factory from "../ContractABI/Factory.json";
import NonFungiblePositionManager from "../ContractABI/NonFungiblePositionManager.json";
import SwapRouter from "../ContractABI/SwapRouter.json";
import PixpelSwap from "../ContractABI/PixpelSwap.json";

export default function useContracts() {
  const addresses = {
    wrappedMatic: "0xdc98A2B40D96E37Ab563544aE06f72e7060602d8",
    factory: "0xE42bD0E7C7bA7fAc938D6b05BB0dfeA6905C17fC",
    swapRouter: "0x327e0037907cC76987AAFcDa9231d4102b0518DA",
    positionManager: "0xBE1a51C2aFBe39Af28C00562C352e0501125aa7b",
    pixpelSwap: "0xe0c36A444AD990C1d2Db0E78F7B417fD2b2Df56C",
  };
  const abi = {
    wrappedMatic: WrappedMatic,
    factory: Factory,
    swapRouter: SwapRouter,
    positionManager: NonFungiblePositionManager,
    liquidityNFT: LiquidityNFT,
    pixpelSwap: PixpelSwap,
  };

  const [contracts, setContracts] = useState({});
  const { signer, walletAddress } = useWallet();

  const getContracts = useCallback(() => {
    if (!walletAddress) return;

    const wrappedMatic = new ethers.Contract(addresses.wrappedMatic, abi.wrappedMatic, signer);
    const factory = new ethers.Contract(addresses.factory, abi.factory, signer);
    const swapRouter = new ethers.Contract(addresses.swapRouter, abi.swapRouter, signer);
    const positionManager = new ethers.Contract(
      addresses.positionManager,
      abi.positionManager,
      signer,
    );
    const liquidityNFT = new ethers.Contract(addresses.positionManager, abi.liquidityNFT, signer);
    const pixpelSwap = new ethers.Contract(addresses.pixpelSwap, abi.pixpelSwap, signer);

    setContracts({
      wrappedMatic,
      factory,
      swapRouter,
      positionManager,
      liquidityNFT,
      pixpelSwap,
    });
  }, [
    abi.factory,
    abi.liquidityNFT,
    abi.pixpelSwap,
    abi.positionManager,
    abi.swapRouter,
    abi.wrappedMatic,
    addresses.factory,
    addresses.pixpelSwap,
    addresses.positionManager,
    addresses.swapRouter,
    addresses.wrappedMatic,
    signer,
    walletAddress,
  ]);

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  return { addresses, contracts };
}
