export const menuList = [
  {
    id: 1,
    title: "OVERVIEW",
  },
  {
    id: 2,
    title: "START ACCOUNT",
  },
  {
    id: 3,
    title: "TOEKN ROOM",
  },
  {
    id: 4,
    title: "GAME BOARD",
  },
  {
    id: 5,
    title: "STAKING PORTFOLIO",
  },
];
