/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useCallback, useState } from "react";
import { fetchCandleStickData } from "./utils/fetchService";
import TradeView from "./TradeView";
import { WS_URL } from "./utils/constants";
import { candleSocketAdaptor } from "./utils/adaptor";
import {
  condleStickDefaultConfig,
  histogramDefaultConfig,
  defaultChartLayout,
} from "./utils/constants";

var __awaiter =
  (this && this.__awaiter) ||
  function (thisArg, _arguments, P, generator) {
    function adopt(value) {
      return value instanceof P
        ? value
        : new P(function (resolve) {
            resolve(value);
          });
    }

    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }

      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }

      function step(result) {
        result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
      }

      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };

var __generator =
  (this && this.__generator) ||
  function (thisArg, body) {
    var _ = {
        label: 0,
        sent: function () {
          if (t[0] & 1) throw t[1];

          return t[1];
        },
        trys: [],
        ops: [],
      },
      f,
      y,
      t,
      g;

    return (
      (g = { next: verb(0), throw: verb(1), return: verb(2) }),
      typeof Symbol === "function" &&
        (g[Symbol.iterator] = function () {
          return this;
        }),
      g
    );

    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }

    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");

      while (_)
        try {
          if (
            ((f = 1),
            y &&
              (t =
                op[0] & 2
                  ? y["return"]
                  : op[0]
                  ? y["throw"] || ((t = y["return"]) && t.call(y), 0)
                  : y.next) &&
              !(t = t.call(y, op[1])).done)
          )
            return t;

          if (((y = 0), t)) op = [op[0] & 2, t.value];

          switch (op[0]) {
            case 0:
            case 1:
              t = op;
              break;
            case 4:
              _.label++;

              return { value: op[1], done: false };
            case 5:
              _.label++;
              y = op[1];
              op = [0];
              continue;
            case 7:
              op = _.ops.pop();
              _.trys.pop();
              continue;
            default:
              if (
                !((t = _.trys), (t = t.length > 0 && t[t.length - 1])) &&
                (op[0] === 6 || op[0] === 2)
              ) {
                _ = 0;
                continue;
              }

              if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                _.label = op[1];
                break;
              }

              if (op[0] === 6 && _.label < t[1]) {
                _.label = t[1];
                t = op;
                break;
              }

              if (t && _.label < t[2]) {
                _.label = t[2];
                _.ops.push(op);
                break;
              }

              if (t[2]) _.ops.pop();

              _.trys.pop();
              continue;
          }

          op = body.call(thisArg, _);
        } catch (e) {
          op = [6, e];
          y = 0;
        } finally {
          f = t = 0;
        }

      if (op[0] & 5) throw op[1];

      return { value: op[0] ? op[1] : void 0, done: true };
    }
  };

var TradeViewChart = function (_a) {
  var _b = _a.pair,
    pair = _b === void 0 ? "BTCBUSD" : _b,
    _c = _a.interval,
    interval = _c === void 0 ? "1m" : _c,
    _d = _a.candleStickConfig,
    candleStickConfig = _d === void 0 ? condleStickDefaultConfig : _d,
    _e = _a.histogramConfig,
    histogramConfig = _e === void 0 ? histogramDefaultConfig : _e,
    _f = _a.chartLayout,
    chartLayout = _f === void 0 ? defaultChartLayout : _f,
    containerStyle = _a.containerStyle;
  var _g = useState(null),
    candleStickData = _g[0],
    setCandleData = _g[1];
  var _h = useState(null),
    updatedata = _h[0],
    setUpdateData = _h[1];
  var fetchCandleData = useCallback(
    function () {
      return __awaiter(void 0, void 0, void 0, function () {
        var candleData;

        return __generator(this, function (_a) {
          switch (_a.label) {
            case 0:
              return [4 /*yield*/, fetchCandleStickData(pair)];
            case 1:
              candleData = _a.sent();
              setCandleData(candleData);

              return [2 /*return*/];
          }
        });
      });
    },
    [pair],
  );
  useEffect(
    function () {
      fetchCandleData();
    },
    [fetchCandleData],
  );
  useEffect(
    function () {
      var ws = new WebSocket(
        "".concat(WS_URL, "/").concat(pair.toLocaleLowerCase(), "@kline_").concat(interval),
      );

      ws.onmessage = function (e) {
        var message = JSON.parse(e.data);
        var parsedMessage = candleSocketAdaptor(message);
        setUpdateData(parsedMessage);
      };

      return function () {
        ws.close();
      };
    },
    [pair, interval],
  );

  if (!candleStickData) {
    return React.createElement("div", { className: "loader" });
  }

  return React.createElement(TradeView, {
    updatedata: updatedata,
    initialChartData: candleStickData,
    candleStickConfig: candleStickConfig,
    histogramConfig: histogramConfig,
    chartLayout: chartLayout,
    containerStyle: containerStyle,
  });
};

export default memo(TradeViewChart);
