import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  setIsAddTokenModalOpen,
  setTokenFromSwapMetamask,
  setTokenToSwapMetamask,
} from "../../store/reducers/SwapMaster/swapSlice";
import { message } from "antd";

const TokenDropdownMeta = ({
  initialContent,
  contentList,
  Max,
  backgroundColor,
  type,
  prevSelected = [],
  disabled,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedContent, setSelectedContent] = useState(initialContent);
  const [bgColor, setBgColor] = useState("bg-app-black");
  const dispatch = useDispatch();
  useEffect(() => {
    setSelectedContent(initialContent);

    if (type === "from") {
      setSelectedContent(initialContent);
      dispatch(setTokenFromSwapMetamask(initialContent));
    } else if (type === "to") {
      setSelectedContent(initialContent);
      dispatch(setTokenToSwapMetamask(initialContent));
    } else {
      setSelectedContent(initialContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialContent]);

  useEffect(() => {
    setBgColor(backgroundColor);
  }, [backgroundColor]);

  const handleDropDown = () => {
    if (disabled) return;

    if (showDropDown) {
      setShowDropDown(false);
    } else {
      setShowDropDown(true);
    }
  };

  const selectHandleClick = content => {
    if (type === "from") {
      dispatch(setTokenFromSwapMetamask(content));
      setSelectedContent(content);
    } else if (type === "to") {
      dispatch(setTokenToSwapMetamask(content));
      setSelectedContent(content);
    } else {
      setSelectedContent(initialContent);
    }

    setShowDropDown(false);
  };

  const handleShowModal = () => {
    dispatch(
      setIsAddTokenModalOpen({
        modal: "createToken",
        isOpen: true,
      }),
    );
  };

  return (
    <div className={"flex flex-row items-center w-10 h-16 rounded cursor-pointer " + bgColor}>
      <div className="flex-none">
        <div
          className={(showDropDown ? "rounded-t-md " : "rounded-md ") + bgColor}
          onClick={handleDropDown}
        >
          <div className="flex flex-row h-16 w-40 px-5 py-5">
            <div className="flex flex-row">
              <img src={selectedContent.icon} alt="" style={selectedContent.style} />
              <div className="mx-1 text-base font-medium">{selectedContent.symbol}</div>
            </div>
            {contentList.length && (
              <div className="flex">
                <svg
                  className="w-5 h-5 text-gray-500 firefox-svg-ml-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            )}
          </div>
          <div className="relative">
            {contentList.length && showDropDown ? (
              <div className="absolute inset-0 z-10">
                <div className="fixed inset-0 w-full h-full" onClick={handleDropDown}></div>
                <div
                  className={
                    "flex flex-col w-64 rounded-b-md max-h-[300px] overflow-y-scroll " + bgColor
                  }
                >
                  <button
                    type="button"
                    className={
                      backgroundColor +
                      " relative flex flex-row items-center justify-start gap-5 px-5 pt-3 disabled:opacity-50 text-app-blue font-medium"
                    }
                    onClick={() => handleShowModal()}
                  >
                    + ADD TOKEN
                  </button>
                  {contentList.map((content, idx) => (
                    <div
                      key={idx}
                      className={
                        bgColor +
                        " relative flex flex-row items-center justify-start gap-5 px-5 py-3" +
                        (selectedContent === content.symbol ? "cursor-not-allowed" : "")
                      }
                      disabled={selectedContent === content.symbol}
                      onClick={() => {
                        if (!prevSelected.includes(content.address?.toLowerCase())) {
                          selectHandleClick(content);
                        } else {
                          message.error("Cannot select same tokens");
                        }
                      }}
                    >
                      <img className="h-6" src={content.icon} alt="" style={content.style} />
                      <div className="text-base font-medium hover:text-app-blue">
                        {content.symbol}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className={"flex-auto " + bgColor}>
        <input className={"w-full " + bgColor} />
      </div>
      {Max ? <div className="flex mx-5 text-app-blue ">MAX</div> : null}
    </div>
  );
};

export default TokenDropdownMeta;
