import React from "react";
import MyProfile from "./ProfileSettings/MyProfile";
import Preferences from "./ProfileSettings/Perferences";

const ProfileSettings = () => {
  return (
    <div>
      <MyProfile />
      <Preferences />
    </div>
  );
};

export default ProfileSettings;
