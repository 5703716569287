import { useEffect, useState } from "react";
import ERC20ABI from "../ContractABI/ERC20.json";
import useWallet from "./useWallet";
import { getTokens } from "../utils/polygonFirebase";
const { ethers } = require("ethers"); // Make sure to include ethers if not already

export default function useTokens() {
  const [tokens, setTokens] = useState([]);
  const [loading, setLoading] = useState(true);
  const { signer, walletAddress } = useWallet();

  const fetchTokensFromApi = async () => {
    try {
      setLoading(true);
      const { data: apiTokens, success, error } = await getTokens();

      if (!success) {
        setTokens([]);
        setLoading(false);
        console.error(error);

        return;
      }

      if (!walletAddress) {
        setTokens(apiTokens);
        setLoading(false);

        return;
      }

      let updatedTokens = apiTokens.map(async token => {
        const contract = new ethers.Contract(token.address, ERC20ABI, signer);

        try {
          const tokenBalance = await contract.balanceOf(walletAddress);
          const formattedBalance = parseFloat(ethers.utils.formatEther(tokenBalance));

          return {
            ...token,
            contract,
            tokenBalance: formattedBalance,
          };
        } catch (error) {
          return {
            ...token,
            contract,
            tokenBalance: 0,
          };
        }
      });

      const tokensWithContract = await Promise.all(updatedTokens);

      setTokens(tokensWithContract);
    } catch (error) {
      console.error("Error fetching tokens from API:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTokensFromApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  async function refetch() {
    await fetchTokensFromApi();
  }

  return { tokens, loading, refetch };
}
