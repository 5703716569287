import React, { useState, useEffect } from "react";
// import Notification from "./Notification";
// import Market from "./Market";
// import Trade from "./Trade";
import cart from "../../asssets/images/shopping-cart.svg";
// import inventoryBlue from "../../asssets/images/inventory-blue.svg";
import cartBlue from "../../asssets/images/cart.svg";
// import inventory from "../../asssets/images/inventory.svg";
import wallet from "../../asssets/images/wallet-header.svg";
import walletBlue from "../../asssets/images/wallet-blue.svg";
import profile from "../../asssets/images/profile.svg";
import profileBlue from "../../asssets/images/profile-blue.svg";
// import logo from "../../asssets/images/logo.svg";
//import notification from "../../asssets/images/notification.svg";
// import { useNavigate } from "react-router-dom";
import Iconmenu from "../../asssets/images/menu.svg";
import { useAppContext } from "../../contexts/AppContext";
import launchpad from "../../asssets/images/launchpad.svg";
import launchpadBlue from "../../asssets/images/launchpad-blue.svg";
import marketplace from "../../asssets/images/marketplace.svg";
import marketplaceblue from "../../asssets/images/marketplace-blue.svg";
import gamedashboard from "../../asssets/images/gamedashboard.svg";
// import gamedashboardblue from "../../asssets/images/gamedashboard-blue.svg";
import nftfactory from "../../asssets/images/nftfactory.svg";
// import nftfactoryblue from "../../asssets/images/nftfactory-blue.svg";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Space, Tooltip } from "antd";
import ConnectFireBlockWallet from "../../models/ConnectFireBlockWallet";
import CreateFireBlocksWallet from "../../models/CreateFireBlocksWallet";
import useWallet from "../../hooks/useWallet";
import { Axios } from "../../utils/axios";
import { handleConnect } from "../../utils/connect";
import logoPlayer from "../../asssets/images/home/PixpelPlayerMode.svg";

// /import ConnectWalletButton from "./ConnectWalletButton/ConnectWalletButton";

const PlayerHeaderPixpel = () => {
  const connect = useSelector(s => s.connect);
  const context = useAppContext();
  const [openMenu, setOpenMenu] = useState(false);
  const [closeModal, setCloseModal] = useState(true);
  const [signUpModal, setSignUpModal] = useState(true);
  const blockChain = context.blockChain;
  const setBlockChain = context.setBlockChain;
  const getWalletFunction = context.getWalletFunction;
  const getEthersInstance = context.getEthersInstance;
  const { walletAddress: walletMeta } = useWallet();
  const fireBlocksAccountLogin = context.fireBlocksAccountLogin;
  // const fireBlocksAccount = context.fireBlocksAccount;
  const fireBlockAccountSignUp = context.fireBlockAccountSignUp;
  const [userData, setUserData] = useState();
  const dispatch = useDispatch();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onClickHandler = () => {
    dispatch(handleConnect());
  };

  function handleClose() {
    setCloseModal(prev => !prev);
  }

  function handleSignUpModal() {
    setSignUpModal(prev => !prev);
  }

  useEffect(() => {
    async function a() {
      await Axios.get("players/checkUser")
        .then(res => {
          const data = res.data.user;
          // function decodeJWT(token) {
          //     const base64Url = token.split('.')[1];
          //     const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          //     const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          //     }).join(''));
          //     return JSON.parse(jsonPayload);
          // }
          // const decodedToken = decodeJWT(token);
          // console.log(decodedToken);
          setUserData(data);
          // localStorage.setItem("user", JSON.parse(data));
          // window.location.href = "https://home.pixpel.io";
        })
        .catch(e => {
          console.error(`Error : ${e}`);
          // window.location.href = "https://home.pixpel.io";
        });
    }

    a();
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <div className="flex gap-2">
          <img src="/assets/images/polygon2.svg" width={20} height={20} alt="menu" />

          <a className={`${walletMeta ? "!text-green-500" : "!text-red-600"} hover:!text-blue-400`}>
            POLYGON
          </a>
        </div>
      ),
      disabled: false,
      onClick: () => {
        setBlockChain("POLYGON");
      },
    },
    {
      key: "0",
      label: (
        <div className="flex gap-2">
          <img src="/assets/images/Ellipse 157.png" width={20} height={20} alt="menu" />
          <a
            className={`${
              connect?.account ? "!text-green-500" : "!text-red-600"
            } hover:!text-blue-400`}
          >
            CONCORDIUM
          </a>
        </div>
      ),
      disabled: false,
      onClick: () => {
        setBlockChain("CONCORDIUM");
        onClickHandler();
      },
    },
    // {
    //   key: "2",
    //   label: (
    //     <a
    //       onClick={() => {
    //         !fireBlocksAccount && handleClose();
    //       }}
    //       className={`${
    //         fireBlocksAccount?.fireblocks_account_address ? "!text-green-500" : "!text-red-600"
    //       } hover:!text-blue-400`}
    //     >
    //       FIREBLOCKS
    //     </a>
    //   ),
    //   disabled: false,
    //   onClick: () => {
    //     setBlockChain("FIREBLOCKS");
    //   },
    // },
  ];
  // const navigate = useNavigate();

  // const handlelogout = async () => {
  //   await Axios.get("/logout");
  //   // localStorage.clear();
  //   window.location.href = "https://home.pixpel.io/?logout=true";
  // };

  const handlelogin = async () => {
    // localStorage.clear();
    window.location.href = "https://home.pixpel.io";
  };

  const walletConnectZetaChain = async () => {
    const networkId = 80002;
    // const networkId = 97;
    getEthersInstance(networkId).catch(e => {
      console.error("Error:", e);
    });
    getWalletFunction();
  };

  useEffect(() => {
    if (blockChain === "POLYGON") {
      walletConnectZetaChain();
    } else {
      onClickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChain]);

  useEffect(() => {
    if (blockChain === "CONCORDIUM") {
      onClickHandler();
    }
  }, []);

  const handleImageClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <>
      <div className="bg-app-black flex justify-between h-[84px] items-center ">
        <div className="flex gap-7  px-8 items-center">
          <div>
            <img
              src={logoPlayer}
              alt="logo"
              onClick={() => (window.location.href = "https://home.pixpel.io")}
              className="cursor-pointer !w-[170px] !h-[48px]"
              width={170}
              height={48}
            />
          </div>
          <div className="hidden xl:flex gap-12">
            {/* <Market /> */}
            {/* <div
              className="relative flex justify-start px-5 border-b-2 border-app-black py-1 hover:text-app-blue"
              onClick={async () => {
                navigate("/nft-market");
                context.setDeveloperHeader(0);
              }}
            >
              NFT
            </div> */}
            {/* <div
            className={
              (context.developerHeader === 0 ? "text-app-blue px-5 py-1" : "") +
              "hover:text-app-blue cursor-pointer px-5 py-1"
            }
            onClick={async () => {
              navigate("/game-market");
              await context.setDeveloperHeader(0);
            }}
          >
            Game
          </div> */}
            <div
              className={
                (context.developerHeader === 1 ? "text-app-blue w-max" : "") +
                "hover:text-app-blue cursor-pointer w-max"
              }
              onClick={async () => {
                window.location.href = "https://launchpad.pixpel.io/";
                context.setDeveloperHeader(1);
              }}
            >
              Launchpad
            </div>
            {/* <Trade /> */}
            <div
              className={"text-app-blue cursor-pointer w-max  "}
              onClick={() => {
                // navigate("/swap-master/swap");
                context.setDeveloperHeader(2);
              }}
            >
              Dex
            </div>
            <div
              className={
                (context.developerHeader === 4 ? "text-app-blue  " : "") +
                "hover:text-app-blue cursor-pointer w-max  "
              }
              onClick={() => {
                window.location.href = "https://nft.pixpel.io/";
                context.setDeveloperHeader(3);
              }}
            >
              NFT
            </div>
            {userData?.player_id && (
              <div
                className={
                  (context.developerHeader === 5 ? "text-app-blue  " : "") +
                  "hover:text-app-blue cursor-pointer w-max  "
                }
                onClick={() => {
                  window.location.href = "https://home.pixpel.io/player/wallet";
                  context.setDeveloperHeader(3);
                }}
              >
                Wallet
              </div>
            )}
            <div
              className={
                (context.developerHeader === 4 ? "text-app-blue  " : "") +
                "hover:text-app-blue cursor-pointer w-max  "
              }
              onClick={() => {
                window.location.href = "https://home.pixpel.io/player/game";
                context.setDeveloperHeader(3);
              }}
            >
              Games
            </div>
          </div>
        </div>
        {/* <ConnectWalletButton /> */}
        {/* <div
          className="hidden lg:flex bg-app-black-button px-10 py-3 rounded-md text-app-blue w-max mr-2"
          // onClick={() => {
          //   context.setPlayer(true);
          // }}
        >
          Player
        </div> */}

        <div className="hidden xl:flex gap-5 items-center mr-[30px]">
          <div className="relative">
            <div
              className="relative inline-block border-2 border-[#0196C9] p-1 rounded-full cursor-pointer hover:bg-slate-400"
              onClick={handleImageClick}
            >
              <Tooltip
                placement="left"
                title={blockChain === "CONCORDIUM" ? "Concordium" : "Polygon"}
              >
                <div className="group">
                  <img
                    src={
                      blockChain === "CONCORDIUM"
                        ? "/assets/images/Ellipse 157.png"
                        : "/assets/images/polygon2.svg"
                    }
                    alt="menu"
                    width={32}
                    height={32}
                    className="!h-8 !w-8 transition duration-300 ease-in-out group-hover:bg-opacity-50"
                  />
                  <div className="hidden group-hover:block absolute inset-0 bg-slate-400 bg-opacity-50 rounded-full"></div>
                </div>
              </Tooltip>
            </div>

            {isDropdownOpen && (
              <div className="absolute  right-2 w-40 bg-app-black shadow-md rounded-b-lg">
                <div
                  className="block w-full px-4 py-2 text-center text-white hover:text-[#0196C9] hover:cursor-pointer focus:outline-none border-b-2 border-[#717A8B]"
                  onClick={() => {
                    localStorage.setItem("Blockchain", "CONCORDIUM");
                    setBlockChain("CONCORDIUM");
                    onClickHandler();
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  Concordium
                </div>
                <div
                  className="block w-full px-4 py-2 text-center text-white hover:text-[#0196C9] hover:cursor-pointer focus:outline-none"
                  onClick={() => {
                    localStorage.setItem("Blockchain", "POLYGON");
                    setBlockChain("POLYGON");
                    setIsDropdownOpen(!isDropdownOpen);
                  }}
                >
                  Polygon
                </div>
              </div>
            )}
          </div>{" "}
          <div className="relative inline-flex flex-col items-start ">
            {/* {context.developerHeader === 1 && (
              <div
                className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                style={{ zIndex: 1 }}
              />
            )} */}
            <Tooltip title="Launchpad" placement="bottom">
              <div
                className=" cursor-pointer"
                onClick={() => {
                  window.location.href = "https://launchpad.pixpel.io";
                  context.setDeveloperHeader(1);
                }}
              >
                <img
                  src={context.developerHeader === 1 ? launchpadBlue : launchpad}
                  alt="cart"
                  className="cursor-pointer"
                />
                <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                  <img src={launchpadBlue} alt="hover-image" className="w-full h-full" />
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="relative inline-flex flex-col items-start ">
            {/* {context.developerHeader === 3 && (
              <div
                className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                style={{ zIndex: 1 }}
              />
            )} */}
            <Tooltip title="Store" placement="bottom">
              <div
                className=" cursor-pointer"
                onClick={() => {
                  window.location.href = "https://nft.pixpel.io/player/store";
                  context.setDeveloperHeader(3);
                }}
              >
                <img src={marketplace} alt="cart" className="cursor-pointer" />
                <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                  <img src={marketplaceblue} alt="hover-image" className="w-full h-full" />
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="relative inline-flex flex-col items-start ">
            {context.developerHeader === 2 && (
              <div
                className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                style={{ zIndex: 1 }}
              />
            )}
            <Tooltip title="Shopping Cart" placement="bottom">
              <div
                className="mt-[2px] cursor-pointer"
                onClick={() => {
                  window.location.href = "https://nft.pixpel.io/player/shopping-cart?show=Cart";
                  context.setDeveloperHeader(2);
                }}
              >
                <img
                  src={cart}
                  alt="cart"
                  onClick={() => {
                    setOpenMenu(false);
                  }}
                />
                <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                  <img src={cartBlue} alt="hover-image" className="w-full h-full" />
                </div>
              </div>
            </Tooltip>
          </div>
          {/* <div className="relative inline-flex flex-col items-start ">
            {context.developerHeader === 4 && (
              <div
                className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                style={{ zIndex: 1 }}
              />
            )}
            <div
              className=" cursor-pointer"
              onClick={() => {
                window.location.href = "https://launchpad.pixpel.io/developer/game-dashboard";
                context.setDeveloperHeader(4);
              }}
            >
              <img
                src={context.developerHeader === 4 ? gamedashboardblue : gamedashboard}
                alt="cart"
                className="cursor-pointer"
              />
              <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                <img src={gamedashboardblue} alt="hover-image" className="w-full h-full" />
              </div>
            </div>
          </div> */}
          {userData?.player_id && (
            <div className="relative inline-flex flex-col items-start ">
              {context.developerHeader === 5 && (
                <div
                  className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                  style={{ zIndex: 1 }}
                />
              )}
              <Tooltip title="Wallet" placement="bottom">
                <div
                  className=" cursor-pointer"
                  onClick={() => {
                    window.location.href = "https://home.pixpel.io/player/wallet";
                    context.setDeveloperHeader(5);
                  }}
                >
                  <img
                    src={context.developerHeader === 5 ? walletBlue : wallet}
                    alt="wallet"
                    className="cursor-pointer"
                  />
                  <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                    <img src={walletBlue} alt="hover-image" className="w-full h-full" />
                  </div>
                </div>
              </Tooltip>
            </div>
          )}
          {/* <Notification /> */}
          {/* <img
            src={context.developerHeader === 6 ? profileBlue : profile}
            alt="profile"
            className="cursor-pointer"
            onClick={() => {
              navigate("/profile");
              context.setDeveloperHeader(6);
            }}
          /> */}
          {userData?.player_id && (
            <div className="relative inline-flex flex-col items-start ">
              {context.developerHeader === 6 && (
                <div
                  className="w-2 h-2 bg-green-500 rounded-full -mt-[10px] mb-1"
                  style={{ zIndex: 1 }}
                />
              )}
              <Tooltip title="Profile" placement="bottom">
                {userData?.img ? (
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      window.location.href = "https://home.pixpel.io/player/profile";
                      context.setDeveloperHeader(6);
                    }}
                  >
                    <img
                      src={userData?.img}
                      alt="hover-image"
                      className="rounded-full cursor-pointer h-[31px] w-[30px]"
                    />
                  </div>
                ) : (
                  <div
                    className=" cursor-pointer"
                    onClick={() => {
                      window.location.href = "https://home.pixpel.io/player/profile";
                      context.setDeveloperHeader(6);
                    }}
                  >
                    <img
                      src={context.developerHeader === 6 ? profileBlue : profile}
                      alt="profile"
                      className="cursor-pointer"
                    />
                    <div className="absolute top-0 left-0 w-full h-full opacity-0 transition-opacity duration-300 ease-in-out hover:opacity-100">
                      <img src={profileBlue} alt="hover-image" className="w-full h-full" />
                    </div>
                  </div>
                )}
              </Tooltip>
            </div>
          )}
          {userData?.player_id ? (
            // <div
            //   className="flex items-center justify-center w-36 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
            //   onClick={handlelogout}
            // >
            //   <svg
            //     className="h-6 w-6 text-white"
            //     width="24"
            //     height="24"
            //     viewBox="0 0 24 24"
            //     strokeWidth="2"
            //     stroke="currentColor"
            //     fill="none"
            //     strokeLinecap="round"
            //     strokeLinejoin="round"
            //   >
            //     <path stroke="none" d="M0 0h24v24H0z" />
            //     <line x1="5" y1="12" x2="19" y2="12" />
            //     <line x1="5" y1="12" x2="11" y2="18" />
            //     <line x1="5" y1="12" x2="11" y2="6" />
            //   </svg>
            //   <h1>LOGOUT</h1>
            // </div>
            ""
          ) : (
            <div
              className="flex items-center justify-center w-36 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
              onClick={handlelogin}
            >
              <svg
                className="h-6 w-6 text-white"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1="5" y1="12" x2="19" y2="12" />
                <line x1="5" y1="12" x2="11" y2="18" />
                <line x1="5" y1="12" x2="11" y2="6" />
              </svg>
              <h1>LOGIN</h1>
            </div>
          )}
          {/* <div>
            <Dropdown
              menu={{ items }}
              className="flex items-center justify-center w-44 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
            >
              <a>
                <Space>
                  {blockChain === "POLYGON" ? (
                    <div
                      className={
                        walletMeta
                          ? "border-2 rounded-full border-green-400"
                          : "border-2 rounded-full border-red-600"
                      }
                    >
                      <img src="/assets/images/polygon2.svg" width={20} height={20} alt="menu" />
                    </div>
                  ) : blockChain === "CONCORDIUM" ? (
                    <div
                      className={
                        connect?.account
                          ? "border-2 rounded-full ml-1 border-green-400"
                          : "border-2 rounded-full ml-1 border-red-600"
                      }
                    >
                      <img
                        src="/assets/images/Ellipse 157.png"
                        className="max-w-[20px]"
                        width={20}
                        height={20}
                        alt="menu"
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        fireBlocksAccount?.fireblocks_account_address
                          ? "border-4 rounded-full border-green-400"
                          : "border-4 rounded-full border-red-600"
                      }
                    >
                      <img
                        src="/assets/images/fireblocks.png"
                        width={20}
                        height={20}
                        className="rounded-full"
                        alt="menu"
                      />
                    </div>
                  )}
                  {blockChain}
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    className="mb-1"
                  >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                  </svg>{" "}
                </Space>
              </a>
            </Dropdown>
          </div> */}
          {/* <div
            className="flex items-center justify-center w-36 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
            onClick={walletConnectZetaChain}
          >
            CONNECT
          </div> */}
        </div>
        <div className="xl:hidden flex cursor-pointer mr-2" onClick={() => setOpenMenu(!openMenu)}>
          <img src={Iconmenu} alt="menu" />
        </div>
        <div
          className={
            (openMenu ? "-translate-x-0" : "translate-x-full") +
            " fixed top-0 right-0 w-screen z-50 min-h-screen bg-black bg-opacity-90 transform shadow-lg shadow-white duration-200"
          }
        >
          <div
            className="h-36 flex bg-black items-center pr-10 justify-end"
            onClick={() => setOpenMenu(!openMenu)}
          >
            <p className="text-5xl cursor-pointer text-white">×</p>
          </div>
          <div className="w-full flex justify-center flex-col items-center gap-8 pt-10">
            <div
              onClick={() => {
                setOpenMenu(false);
                window.location.href = "https://launchpad.pixpel.io";
              }}
              className="hover:text-app-blue cursor-pointer"
            >
              Launchpad
            </div>
            <div
              onClick={() => {
                setOpenMenu(false);
                window.location.href = "https://nft.pixpel.io";
              }}
              className="hover:text-app-blue cursor-pointer"
            >
              NFT
            </div>
            <div
              onClick={() => {
                setOpenMenu(false);
                // navigate("/swap-master/swap/developer");
              }}
              className="hover:text-app-blue cursor-pointer"
            >
              DEX
            </div>
            <div
              className="hover:text-app-blue cursor-pointer"
              onClick={() => {
                window.location.href = "https://home.pixpel.io/player/game";
                context.setDeveloperHeader(3);
              }}
            >
              Games
            </div>
            {/* <div
            onClick={() => {
              setOpenMenu(false);
              window.location.href = "https://home.pixpel.io/developer/wallet";
            }}
            className="hover:text-app-blue cursor-pointer"
          >
            Wallet
          </div> */}

            <Dropdown
              menu={{ items }}
              className="flex items-center justify-center w-44 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
            >
              <a>
                <Space>
                  {blockChain === "POLYGON" ? (
                    <div
                      className={
                        walletMeta
                          ? "border-4 rounded-full border-green-400"
                          : "border-4 rounded-full border-red-600"
                      }
                    >
                      <img
                        src="/assets/images/polygon2.svg"
                        width={20}
                        height={20}
                        alt="menu"
                        className="!w-[20px] !h-[20px]"
                      />
                    </div>
                  ) : (
                    <div
                      className={
                        connect?.account
                          ? "border-2 rounded-full ml-1 border-green-400"
                          : "border-2 rounded-full ml-1 border-red-600"
                      }
                    >
                      <img
                        src="/assets/images/Ellipse 157.png"
                        className="!w-[20px] !h-[20px]"
                        width={20}
                        height={20}
                        alt="menu"
                      />
                    </div>
                  )}
                  {blockChain}
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="down"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                    className="mb-1"
                  >
                    <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
                  </svg>{" "}
                </Space>
              </a>
            </Dropdown>
            {userData?.player_id ? (
              // <div
              //   className="flex items-center justify-center w-36 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
              //   onClick={handlelogout}
              // >
              //   <svg
              //     className="h-6 w-6 text-white"
              //     width="24"
              //     height="24"
              //     viewBox="0 0 24 24"
              //     strokeWidth="2"
              //     stroke="currentColor"
              //     fill="none"
              //     strokeLinecap="round"
              //     strokeLinejoin="round"
              //   >
              //     <path stroke="none" d="M0 0h24v24H0z" />
              //     <line x1="5" y1="12" x2="19" y2="12" />
              //     <line x1="5" y1="12" x2="11" y2="18" />
              //     <line x1="5" y1="12" x2="11" y2="6" />
              //   </svg>
              //   <h1>LOGOUT</h1>
              // </div>
              ""
            ) : (
              <div
                className="flex items-center justify-center w-36 h-12 rounded-lg bg-app-black-button hover:bg-app-blue gap-2 cursor-pointer"
                onClick={handlelogin}
              >
                <svg
                  className="h-6 w-6 text-white"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" />
                  <line x1="5" y1="12" x2="19" y2="12" />
                  <line x1="5" y1="12" x2="11" y2="18" />
                  <line x1="5" y1="12" x2="11" y2="6" />
                </svg>
                <h1>LOGIN</h1>
              </div>
            )}
            <div className="flex gap-5">
              <img
                src={launchpad}
                alt="cart"
                className="cursor-pointer"
                onClick={() => {
                  setOpenMenu(false);
                  window.location.href = "https://launchpad.pixpel.io/";
                }}
              />
              <img
                src={nftfactory}
                className="cursor-pointer"
                alt="cart"
                onClick={() => {
                  setOpenMenu(false);
                  window.location.href = "https://nft.pixpel.io/";
                }}
              />
              {/* <img
              src={marketplace}
              alt="notification"
              onClick={() => {
                setOpenMenu(false);
                window.location.href ="/notification");
              }}
            /> */}
              <img
                src={gamedashboard}
                className="cursor-pointer"
                alt="inventory"
                onClick={() => {
                  setOpenMenu(false);
                  window.location.href = "https://launchpad.pixpel.io/";
                }}
              />
              {userData?.player_id && (
                <>
                  <img
                    src={wallet}
                    className="cursor-pointer"
                    alt="wallet"
                    onClick={() => {
                      setOpenMenu(false);
                      window.location.href = "https://home.pixpel.io/";
                    }}
                  />
                  <img
                    src={userData?.img ? userData?.img : profile}
                    alt="profile"
                    className="h-[31px] cursor-pointer rounded-full w-[30px]"
                    onClick={() => {
                      setOpenMenu(false);
                      window.location.href = "https://home.pixpel.io/";
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {!closeModal ? (
        <ConnectFireBlockWallet
          setWallet={fireBlocksAccountLogin}
          signUp={handleSignUpModal}
          closeModal={handleClose}
        />
      ) : !signUpModal ? (
        <CreateFireBlocksWallet
          setWallet={fireBlockAccountSignUp}
          signUp={handleClose}
          closeModal={handleSignUpModal}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default PlayerHeaderPixpel;
