export const collectionList = [
  {
    id: 1,
    title: "The Warrior",
  },
  {
    id: 2,
    title: "The Warrior",
  },
  {
    id: 3,
    title: "The Warrior",
  },
  {
    id: 4,
    title: "The Warrior",
  },
];

export const statsList = [
  {
    id: 1,
    title: "Stats",
  },
  {
    id: 2,
    title: "Stats",
  },
  {
    id: 3,
    title: "Stats",
  },
  {
    id: 4,
    title: "Stats",
  },
];

export const subCollectionList = [
  {
    id: 1,
    title: "Https://pixpel.com/collection",
  },
  {
    id: 2,
    title: "Https://pixpel.com/collection",
  },
  {
    id: 3,
    title: "Https://pixpel.com/collection",
  },
  {
    id: 4,
    title: "Https://pixpel.com/collection",
  },
];

export const ChainList = [
  {
    id: 1,
    title: "Binance",
  },
  {
    id: 2,
    title: "Polygon",
  },
  {
    id: 3,
    title: "Solana",
  },
  {
    id: 4,
    title: "Ehtereum",
  },
];
