import { message } from "antd";
import { useState } from "react";
import { useAppContext } from "../contexts/AppContext";

const ConnectFireBlockWallet = ({ closeModal, setWallet, signUp }) => {
  const [wallet, setwallet] = useState();
  const context = useAppContext();

  const customFunction = data => {
    try {
      setWallet(data);
      message.success("FireBlocks Connected", {
        style: {
          marginTop: 20,
          fontSize: 20,
          color: "green !important",
          backgroundColor: "transparent !important",
          border: "1px solid green !important",
          padding: "10px 20px !important",
          borderRadius: 5,
        },
      });
      closeModal();
    } catch (e) {
      message.error(e.message);
    }
  };

  function handleSignUp() {
    signUp();
    closeModal();
  }

  const fireBlockWallet = context.fireBlockWallet;

  return (
    <div className="fixed inset-0 z-10 overflow-y-auto">
      <div className="fixed inset-0 w-full h-full bg-black opacity-40" onClick={closeModal} />
      <div className="flex items-center min-h-screen px-4 py-8">
        <div className="relative flex flex-col w-full sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] mx-auto text-lg bg-app-black-modal rounded-xl xs:w-auto">
          <div className="flex mb-5 justify-between ">
            <div className="text-md font-medium text-center 2xs:text-2xl flex items-center justify-center w-full">
              <p className="text-center">Connect FireBlocks</p>
            </div>
            <button onClick={closeModal} className="bg-[#717a8b9d] py-3 px-5 rounded-lg">
              x
            </button>
          </div>
          <div className="flex flex-col gap-1 py-2 rounded-md 2xs:py-5 2xs:px-7 mb-7">
            <div className="flex justify-between items-center">
              <p>Wallet Number</p>
            </div>
            <hr
              className="opacity-[0.5]"
              style={{ border: "solid 1px rgb(255, 255, 255, 0.23)", borderTop: "1px !important" }}
            />
            <div className="bg-app-black mt-3 flex rounded-md mx-1 justify-between px-5 py-4">
              <input
                placeholder="0x345b..."
                type="text"
                onChange={e => setwallet(e.target.value)}
                className="bg-transparent"
                style={{
                  boxShadow: "none",
                  border: "none",
                  outline: "none",
                }}
                onFocus={e => {
                  e.target.style.boxShadow = "none";
                }}
              />
            </div>
            <p onClick={handleSignUp} className="text-[#0095C8] cursor-pointer text-sm ml-1">
              Don`t have an Account? SignUp Now!
            </p>
            {fireBlockWallet && (
              <>
                <p className="text-gray-700 text-md ml-1">{fireBlockWallet}</p>
                <span className=" text-sm text-red-600">Save this address in your Computer</span>
              </>
            )}
          </div>
          <div className="my-10">
            <button
              onClick={() => {
                customFunction(wallet);
              }}
              className="w-full rounded-lg py-4 bg-[#0095C8] hover:bg-[#76dafc]"
            >
              Connect Wallet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectFireBlockWallet;
