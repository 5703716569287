import BigNumber from "bignumber.js";
import { useDispatch, useSelector } from "react-redux";

// Components
import { MainButton } from "../../../components/Button/MainButton";

// Utils
import { getShortTokenName, getTokenUiAmount } from "../../../utils/format";
import { getExchanges, isSameToken } from "../utils";
// Actions
import { setLiquidityTokenTo } from "../../../store/reducers/SwapMaster/liquiditySlice";

// Constants
import { message } from "antd";
import { useEffect, useRef } from "react";
import { CCD_DECIMALS } from "../../../config";
import { pixpelRequest } from "../../../utils/axios";

const LiquidityPools = ({ openLiquidityForm }) => {
  const tokenList = useSelector(s => s.swapMaster.tokenList);
  const exchanges = useSelector(s => s.swapMaster.exchanges);
  const dispatch = useDispatch();

  const isTokenListLoaded = useSelector(s => s.swapMaster.isTokenListLoaded);

  const handleOpenForm =
    (tokenToData, isUnstakeMode = false, isCreateMode = false) =>
    () => {
      if (tokenToData) dispatch(setLiquidityTokenTo(tokenToData));

      openLiquidityForm({ isUnstakeMode, isCreateMode });
    };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const saveUserExchangeData = async () => {
      try {
        dispatch(getExchanges());
        const payload = exchanges;
        await pixpelRequest.post("/exchanges/updateexchange", payload);
      } catch (error) {
        console.error("Error:", error.message);
      }
    };

    if (!hasEffectRun.current) {
      saveUserExchangeData();
      hasEffectRun.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const hasEffectRun = useRef(false);

  const handleCreateLiquidity = () => {
    const newExchangeTokenToData = tokenList.find(
      ({ address, tokenId }) =>
        address &&
        exchanges.length &&
        exchanges.every(exchange => {
          const { index, subindex } = address; // Corrected

          return !isSameToken(
            { index: address.index, subindex: address.subindex, tokenId }, // Corrected
            { index, subindex, tokenId: exchange.tokenId }, // Corrected
          );
        }),
    );
    handleOpenForm(newExchangeTokenToData, false, true)();
  };

  if (!isTokenListLoaded) return null;

  const exchangesTable = (
    <div className="flex flex-col mb-5">
      <div className="hidden md1:flex">
        <div className="w-1/3 text-gray-500 text-sm md:text-base">Pair</div>
        <div className="w-1/3 text-gray-500 text-sm md:text-base text-center">LP</div>
      </div>
      {exchanges.reduce((acc, { token, lpTokensHolderBalance }, i) => {
        const tokenFrom = tokenList[0];
        const tokenTo = tokenList.find(({ address, tokenId }) => {
          const { index, subindex } = token.address;

          return (
            address &&
            isSameToken(
              { index: address.index, subindex: address.subindex, tokenId },
              { index, subindex, tokenId: token.id },
            )
          );
        });

        if (!tokenTo) return acc;

        acc.push(
          <div
            key={i}
            className="flex text-lg border-b-2 border-gray-500 py-4 items-center flex-col md1:flex-row"
          >
            <div className="flex md1:w-1/3 gap-2">
              <img className="h-6" src={tokenFrom.images?.thumbnail.url} alt={tokenFrom.symbol} />
              <p title={tokenFrom.symbol}>{getShortTokenName(tokenFrom.symbol)}</p>
              <span>/</span>
              <img
                className="h-6"
                src={tokenTo.images?.thumbnail.url}
                alt={tokenTo.symbol}
                style={tokenTo.style}
              />
              <p title={tokenTo.symbol}>{getShortTokenName(tokenTo.symbol)}</p>
            </div>
            <div className="md1:w-1/3 text-center">
              {getTokenUiAmount(BigNumber(lpTokensHolderBalance), CCD_DECIMALS)}
            </div>
            <div className="flex md1:w-1/3 justify-end">
              <MainButton
                className="w-28 h-14 bg-app-green hover:!bg-[#1FF19F] mr-8"
                onClick={handleOpenForm(tokenTo)}
              >
                Stake
              </MainButton>
              {/* <MainButton className="w-28 h-14 bg-app-red" onClick={handleOpenForm(tokenTo, true)}>
                Unstake
              </MainButton> */}
              <MainButton
                className="w-28 h-14 bg-app-red hover:!bg-[#FF8096]"
                onClick={() => {
                  if (getTokenUiAmount(BigNumber(lpTokensHolderBalance), CCD_DECIMALS) > 0) {
                    handleOpenForm(tokenTo, true)();
                  } else {
                    message.error("You don't have LP in this pool");
                  }
                }}
              >
                Unstake
              </MainButton>
            </div>
          </div>,
        );

        return acc;
      }, [])}
    </div>
  );

  return (
    <div className="flex flex-col mx-auto my-0 w-184">
      <div className="flex justify-center md1:justify-end">
        <MainButton
          className="w-28 h-14 bg-app-green hover:!bg-[#1FF19F]"
          onClick={handleCreateLiquidity}
        >
          Create
        </MainButton>
      </div>
      {exchanges.length ? exchangesTable : <div className="text-center">No exchanges found</div>}
    </div>
  );
};

export default LiquidityPools;
