// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-modal {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}
.add-modal .add-modal-container {
  overflow: auto;
  position: relative;
  width: 35%;
  max-height: 60%;
  padding: 30px 30px;
  background-color: #1F2630;
  border-radius: 1em;
}
.add-modal .add-modal-container .add-modal-head {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
}
.add-modal .add-modal-container .add-modal-head .btn {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-right: 15px;
  padding-left: 15px;
}
.add-modal .add-modal-container .add-modal-body h4, .add-modal .add-modal-container .add-modal-body p {
  text-align: center;
}
.add-modal .add-modal-container .add-modal-body p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #717A8B;
}
@media (max-width: 767px) {
  .add-modal .add-modal-container {
    width: 95%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/AddModal/AddModal.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,UAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AACJ;AACI;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;AACN;AACM;EACE,2BAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACR;AAKM;EACE,kBAAA;AAHR;AAKM;EACE,sBAAA;EACA,kBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAHR;AAOI;EAvCF;IAwCI,UAAA;EAJJ;AACF","sourcesContent":[".add-modal {\n  position: absolute;\n  z-index: 2;\n  top: 0;\n  left: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.4);\n\n  .add-modal-container {\n    overflow: auto;\n    position: relative;\n    width: 35%;\n    max-height: 60%;\n    padding: 30px 30px;\n    background-color: #1F2630;\n    border-radius: 1em;\n\n    .add-modal-head {\n      position: absolute;\n      top: 20px;\n      right: 20px;\n      display: flex;\n      gap: 15px;\n\n      .btn {\n        padding-top: 5px !important;\n        padding-bottom: 5px !important;\n        padding-right: 15px;\n        padding-left: 15px;\n      }\n    }\n\n    .add-modal-body {\n      \n      h4, p {\n        text-align: center;\n      }\n      p {\n        font-family: 'Poppins';\n        font-style: normal;\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 24px;\n        color: #717A8B;\n      }\n    }\n\n    @media (max-width: 767px) {\n      width: 95%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
