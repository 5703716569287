import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import LiquidityPools from "./LiquidityPools";
import LiquidityPoolsMeta from "./LiquidityPoolsMeta";
import LiquidityCard from "./LiquidityCard";

// Hooks
import { useLiquidityDataUpdate } from "./hooks";

// Actions
import { setLiquidityActiveWindow } from "../../../store/reducers/SwapMaster/liquiditySlice";
import { LIQUIDITY_WINDOWS } from "./constants";
import { useAppContext } from "../../../contexts/AppContext";

const Liquidity = () => {
  const dispatch = useDispatch();
  const [create, setCreate] = useState(false);
  const [poolToken, setPoolToken] = useState([]);
  const { blockChain } = useAppContext();
  const activeWindow = useSelector(s => s.liquidity.activeWindow);
  const [formParameters, setFormParameters] = useState({});

  useLiquidityDataUpdate();

  // console.log(poolToken, "POOL");
  const openLiquidityForm = params => {
    setFormParameters(params);
    dispatch(setLiquidityActiveWindow(LIQUIDITY_WINDOWS.form));
  };

  if (activeWindow === LIQUIDITY_WINDOWS.pools) {
    if (blockChain === "CONCORDIUM") {
      return <LiquidityPools openLiquidityForm={openLiquidityForm} />;
    } else {
      return (
        <LiquidityPoolsMeta
          openLiquidityForm={openLiquidityForm}
          setPoolToken={setPoolToken}
          setCreate={setCreate}
        />
      );
    }
  }

  if (activeWindow === LIQUIDITY_WINDOWS.form) {
    return <LiquidityCard {...formParameters} create={create} poolToken={poolToken} />;
  }
};

export default Liquidity;
