import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export const createToken = async data => {
  try {
    const newData = { tokenBalance: 0, ...data, address: data.address };
    const doc = await addDoc(collection(db, "tokens"), newData);

    return { success: true, error: null, data: { ...newData, id: doc.id, contract: null } };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getTokens = async () => {
  try {
    const docQuery = await getDocs(collection(db, "tokens"));
    const tokens = docQuery.docs.map(doc => ({ ...doc.data(), id: doc.id, contract: null }));

    return {
      success: true,
      error: null,
      data: tokens,
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getToken = async id => {
  try {
    const docQuery = await getDoc(doc(db, "tokens", id));
    const token = { ...docQuery.data(), id: docQuery.id, contract: null };

    return {
      success: true,
      error: null,
      data: token,
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const deleteToken = async id => {
  try {
    await deleteDoc(doc(db, "tokens", id));

    return {
      success: true,
      error: null,
      data: "Deleted successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const updateToken = async (id, data) => {
  try {
    await updateDoc(doc(db, "tokens", id), data);

    return {
      success: true,
      error: null,
      data: "Updated successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const createPair = async data => {
  try {
    const newData = {
      tokensOwed0: 0,
      tokensOwed1: 0,
      liquidity: 0,
      totalSupply: 0,
      liquidityProvided: 0,
      tokenId: "",
      tokens: [data.tokenA.address, data.tokenB.address],
      ...data,
    };
    const doc = await addDoc(collection(db, "pairs"), newData);

    return { success: true, error: null, data: { ...newData, id: doc.id } };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getPairs = async () => {
  try {
    const docQuery = await getDocs(collection(db, "pairs"));
    const Pairs = docQuery.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      contract: null,
      sqrtPriceX96: 0,
    }));
    const { data: tokens } = await getTokens();
    const pairs = Pairs.map(pair => {
      const pairTokens = pair.tokens.map(pairtoken =>
        tokens.find(token => token.address.toLowerCase() === pairtoken.toLowerCase()),
      );

      return { ...pair, tokenA: pairTokens[0], tokenB: pairTokens[1] };
    });

    return {
      success: true,
      error: null,
      data: pairs,
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getPair = async id => {
  try {
    const docQuery = await getDoc(doc(db, "pairs", id));
    const Pair = { ...docQuery.data(), id: docQuery.id, contract: null, sqrtPriceX96: 0 };
    const { data: tokens } = await getTokens();
    const pairTokens = Pair.tokens.map(pairtoken =>
      tokens.find(token => token.address.toLowerCase() === pairtoken.toLowerCase()),
    );

    return {
      success: true,
      error: null,
      data: { ...Pair, tokenA: pairTokens[0], tokenB: pairTokens[1] },
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const deletePair = async id => {
  try {
    await deleteDoc(doc(db, "pairs", id));

    return {
      success: true,
      error: null,
      data: "Deleted successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const updatePair = async (id, data) => {
  try {
    await updateDoc(doc(db, "pairs", id), data);

    return {
      success: true,
      error: null,
      data: "Updated successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const createPairData = async data => {
  try {
    const doc = await addDoc(collection(db, "pairsData"), data);

    return { success: true, error: null, data: { ...data, id: doc.id } };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getPairsData = async () => {
  try {
    const docQuery = await getDocs(collection(db, "pairsData"));
    const Pairs = docQuery.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    return {
      success: true,
      error: null,
      data: Pairs,
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const getPairData = async id => {
  try {
    const docQuery = await getDoc(doc(db, "pairsData", id));
    const Pair = { ...docQuery.data(), id: docQuery.id };

    return {
      success: true,
      error: null,
      data: Pair,
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const deletePairData = async id => {
  try {
    await deleteDoc(doc(db, "pairsData", id));

    return {
      success: true,
      error: null,
      data: "Deleted successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};

export const updatePairData = async (id, data) => {
  try {
    await updateDoc(doc(db, "pairsData", id), data);

    return {
      success: true,
      error: null,
      data: "Updated successfully",
    };
  } catch (error) {
    return { success: false, error: error?.response?.data?.message || error?.message, data: null };
  }
};
