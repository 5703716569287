import { useEffect, useState, useMemo } from "react";
import { ethers } from "ethers";

export default function useWallet() {
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [gasPrice, setGasPrice] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initWallet = async () => {
      try {
        if (typeof window.ethereum === "undefined") {
          throw new Error("Ethereum provider not found");
        }

        const providerInstance = new ethers.providers.Web3Provider(window.ethereum);
        const signerInstance = providerInstance.getSigner();

        // Function to update wallet state
        const updateWalletState = async () => {
          const [gasPrice, walletAddress] = await Promise.all([
            signerInstance.getGasPrice(),
            signerInstance.getAddress(),
          ]);

          setGasPrice(gasPrice);
          setWalletAddress(walletAddress);
        };

        // Initialize wallet state
        await updateWalletState();

        // Subscribe to provider changes
        window.ethereum.on("chainChanged", async () => {
          await updateWalletState();
        });

        setProvider(providerInstance);
        setSigner(signerInstance);
      } catch (err) {
        setError(err.message);
        console.error("Error initializing wallet:", err);
      }
    };

    initWallet();

    // Cleanup function
    return () => {
      if (window.ethereum) {
        window.ethereum.removeAllListeners("chainChanged");
      }
    };
  }, []);

  const wallet = useMemo(
    () => ({
      provider,
      signer,
      gasPrice,
      walletAddress,
      error,
      setWalletAddress,
    }),
    [provider, signer, gasPrice, walletAddress, error],
  );

  return wallet;
}
