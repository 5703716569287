import axios from "axios";

const URL = "https://backend.pixpel.io";
// const LOCAL_URL = "http://localhost:3001";
// Constants
import { JS_NODE_URL } from "../config";

export const pixpelRequest = axios.create({
  responseType: "json",
  baseURL: `${JS_NODE_URL}/api/v1`,
});

export const Axios = axios.create({
  baseURL: `${URL}`,
  headers: {
    cookie: typeof document !== "undefined" ? document.cookie : "",
    Cookie: typeof document !== "undefined" ? document.cookie : "",
  },
  withCredentials: true,
});
