import { useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

// Components
import TokenSelectInput from "../../../components/TokenSelectInput/TokenSelectInput.js";
import { MainButton } from "../../../components/Button/MainButton.js";

// Utils
import { changeSwapDirection, getMaxCcdAmount } from "./utils/index.js";
import { capitalizeString } from "../../../utils/common.js";

// Hooks
import { useSwapDataUpdate } from "./hooks.js";

// Actions
import {
  setIsSwapModalOpen,
  setSwapTokenFrom,
  setSwapTokenTo,
} from "../../../store/reducers/SwapMaster/swapSlice.js";

import SlippageModal from "./SlippageModal.js";
// Icons
import { SwapDirectionIcon } from "../icons/SwapDirectionIcon.js";

// Constants
import { SWAP_FORM_FIELDS } from "./constants.js";
import { useState } from "react";

const SwapForm = () => {
  const dispatch = useDispatch();
  const balanceFrom = useSelector(s => s.swap.balance.from);
  const tokenFrom = useSelector(s => s.swap.tokenFrom);
  const tokenTo = useSelector(s => s.swap.tokenTo);
  const balanceTo = useSelector(s => s.swap.balance.to);
  const isNoFilledPools = useSelector(s => s.swapMaster.isNoFilledPools);
  const [slippage, setSlippage] = useState(false);

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    getValues,
    setValue,
  } = useFormContext();
  const currentValues = getValues();

  const { tokenList, fromPerToAmount, toPerFromAmount } = useSwapDataUpdate();

  const onSubmit = values => {
    dispatch(
      setIsSwapModalOpen({
        modal: "confirm",
        isOpen: true,
        modalData: { values, fromPerToAmount, toPerFromAmount },
      }),
    );
  };

  function handleSlippage() {
    setSlippage(prev => !prev);
  }

  const handleSwapDirection = () => {
    dispatch(changeSwapDirection());
  };

  const onMaxHandler = () => {
    const isCCD = !tokenFrom.address;
    const balance = isCCD ? getMaxCcdAmount(balanceFrom) : balanceFrom;

    setValue(SWAP_FORM_FIELDS.to, balance, { shouldValidate: true, shouldTouch: true });
  };

  const onMaxToHandler = () => {
    const isCCD = !tokenFrom.address;
    const balance = isCCD ? getMaxCcdAmount(balanceTo) : balanceTo;
    setValue(SWAP_FORM_FIELDS.from, balance, { shouldValidate: true, shouldTouch: true });
  };

  const errorMessage = Object.values(errors)
    .map(({ message }) => message)
    .join(", ");

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={`flex flex-col rounded-md w-full 2xl:w-155 bg-app-black sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] ${
          isNoFilledPools ? "relative pointer-events-none" : ""
        }`}
      >
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">From</div>
          <div className="font-normal text-gray-400">Balance: {balanceFrom}</div>
        </div>
        <TokenSelectInput
          name={SWAP_FORM_FIELDS.from}
          tokenList={tokenList}
          selectedToken={tokenFrom}
          disabledToken={tokenTo}
          backgroundColor="bg-app-black-button"
          onTokenSelect={tokenData => {
            dispatch(setSwapTokenFrom(tokenData));
          }}
          isWithMaxButton
          onMaxHandler={onMaxHandler}
          isAddToken
        />
        <div className="flex justify-center w-full mt-5">
          <div
            className="flex items-center justify-center rounded-full cursor-pointer full bg-app-black-button hover:bg-[#717A8B]"
            style={{ marginBottom: "10px", width: "53px", height: "53px" }}
            onClick={handleSwapDirection}
            title="Revert swap direction"
          >
            <SwapDirectionIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">To</div>
          <div className="font-normal text-gray-400">Balance: {balanceTo}</div>
        </div>
        {/* <TokenSelectInput
        name={SWAP_FORM_FIELDS.to}
        tokenList={tokenList}
        selectedToken={tokenTo}
        disabledToken={tokenFrom}
        backgroundColor="bg-app-black-button"
        onInput={onInputTokenPair}
        isWithMaxButton={!isUnstakeMode}
        onMaxHandler={onMaxToHandler}
        onTokenSelect={tokenData => {
          dispatch(setLiquidityTokenTo(tokenData));
        }}
        readOnly={isUnstakeMode}
        isSelectDisabled={isUnstakeMode}
        isAddToken={isCreateMode}
      /> */}
        <TokenSelectInput
          name={SWAP_FORM_FIELDS.to}
          tokenList={tokenList}
          selectedToken={tokenTo}
          disabledToken={tokenFrom}
          backgroundColor="bg-app-black-button"
          // readOnly
          onTokenSelect={tokenData => {
            dispatch(setSwapTokenTo(tokenData));
          }}
          isAddToken
          onMaxHandler={onMaxToHandler}
          isWithMaxButton
        />
        <div className="flex flex-col gap-2 mt-5 text-sm border-b-2 border-app-black">
          <div className="flex flex-row justify-between">
            <div>Price</div>
            <div>
              1 {tokenFrom.symbol} = {toPerFromAmount} {tokenTo.symbol}
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <div>Inverse Price</div>
            <div>
              1 {tokenTo.symbol} = {fromPerToAmount} {tokenFrom.symbol}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <div>You will receive</div>
            <div className="text-2xl text-app-blue">{currentValues[SWAP_FORM_FIELDS.to]}</div>
          </div>
        </div>
        <div className="mt-2">
          <div className="text-sm text-slate-400">Please confirm conversion within the time.</div>
        </div>
        <div className="my-5 bg-white/10 rounded-md p-5">
          {" "}
          1 {tokenFrom.symbol} = {toPerFromAmount} {tokenTo.symbol}
        </div>
        <div className="flex flex-row justify-between">
          <div>Pool Fee</div>
          <div>0.01%</div>
        </div>
        <div className="flex flex-row justify-between">
          <div>Price Impact </div>
          <div>0.23%</div>
        </div>
        <div className="flex flex-row items-center justify-between mb-4">
          <div>Receive at least</div>
          <div className="text-2xl text-app-blue">28.65558 PIXP.USDT</div>
        </div>
        <div className="flex flex-row items-center justify-end mb-3 -my-2">
          <div className=" text-slate-500 text-sm">(Slippage 15%)</div>
        </div>
        <div className="flex flex-row items-center justify-between mb-4">
          <div>Estimated NRG Fee</div>
          <div className="text-2xl text-app-blue">80.35691 CCD</div>
        </div>
        <MainButton
          disabled={slippage}
          onClick={() => handleSlippage()}
          className="p-4 h-16 mt-5 bg-[#37404C] text-lg disabled:bg-[#717A8B] hover:!bg-[#717A8B] "
        >
          Gas and Slippage
        </MainButton>
        <MainButton
          type="submit"
          disabled={!!errorMessage || isSubmitting}
          className="p-4 h-16 mt-5 bg-app-blue text-lg disabled:bg-app-black-button hover:bg-[#50D0FB] "
        >
          {capitalizeString(errorMessage) || "Confirm"}
        </MainButton>
        {isNoFilledPools && (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-25 flex items-start justify-center">
            <p className="text-lg pt-4">No filled pools found</p>
          </div>
        )}
      </form>
      {slippage ? <SlippageModal closeModal={handleSlippage} /> : ""}
    </>
  );
};

export default SwapForm;
