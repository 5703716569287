import { useDispatch, useSelector } from "react-redux";
// Components
import { MainButton } from "../../../components/Button/MainButton";

// Utils
import { getShortTokenName } from "../../../utils/format";
// Actions
import {
  setTokenFromSwapMetamask,
  setTokenToSwapMetamask,
} from "../../../store/reducers/SwapMaster/swapSlice";

// Constants
import { message } from "antd";
import Loader from "../../../components/Loader/Loader";
import usePairs from "../../../hooks/usePairs";
import useTokens from "../../../hooks/useTokens";

const LiquidityPools = ({ openLiquidityForm, setCreate, setPoolToken }) => {
  const dispatch = useDispatch();
  const { pairs, loading, providedLoading } = usePairs();
  const { tokens, loading: tokensLoading } = useTokens();

  const isTokenListLoaded = useSelector(s => s.swapMaster.isTokenListLoaded);

  const handleOpenForm =
    (tokenFrom, tokenToData, isUnstakeMode = false, isCreateMode = true) =>
    () => {
      if (tokenFrom) dispatch(setTokenFromSwapMetamask(tokenFrom));

      if (tokenToData) dispatch(setTokenToSwapMetamask(tokenToData));

      openLiquidityForm({ isUnstakeMode, isCreateMode });
    };

  const handleCreateLiquidity = handleOpenForm();

  if (!isTokenListLoaded) return null;

  const exchangesTable = (
    <div className="flex flex-col mb-5">
      <div className="hidden md1:flex">
        <div className="w-1/3 text-gray-500 text-sm md:text-base">Pair</div>
        <div className="w-1/3 text-gray-500 text-sm md:text-base text-center">LP</div>
      </div>
      {pairs.length > 0
        ? pairs.map((val, key) => {
            const tokenA = tokens.find(token => token.address === val?.tokens?.[0]);

            const tokenB = tokens.find(token => token.address === val?.tokens?.[1]);

            return (
              <div
                key={key}
                className="flex text-lg border-b-2 border-gray-500 py-4 items-center flex-col md1:flex-row"
              >
                <div className="flex md1:w-1/3 gap-2">
                  <img className="h-6" src={val?.tokenA?.icon} alt={val?.tokenA?.symbol} />
                  <p title={val?.symbol}>{getShortTokenName(val?.tokenA?.symbol)}</p>
                  <span>/</span>
                  <img className="h-6" src={val?.tokenB?.icon} alt={val?.tokenB?.symbol} />
                  <p title={val?.tokenB?.symbol}>{getShortTokenName(val?.tokenB?.symbol)}</p>
                </div>
                <div className="md1:w-1/3 text-center">
                  {loading ? <Loader /> : providedLoading ? <Loader /> : val?.liquidityProvided}
                </div>
                <div className="flex md1:w-1/3 justify-end">
                  <MainButton
                    onClick={() => {
                      handleOpenForm(tokenA, tokenB, false, false)();
                      setPoolToken([tokenA, tokenB]);
                    }}
                    className="w-28 h-14 bg-app-green hover:!bg-[#1FF19F] mr-8"
                  >
                    Stake
                  </MainButton>
                  <MainButton
                    className="w-28 h-14 bg-app-red hover:!bg-[#FF8096]"
                    onClick={() => {
                      if (val?.liquidityProvided > 0) {
                        handleOpenForm(tokenA, tokenB, true, false)();
                        setPoolToken([tokenA, tokenB]);
                      } else {
                        message.error("You don't have LP in this pool");
                      }
                    }}
                    disabled={providedLoading}
                  >
                    Unstake
                  </MainButton>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );

  return (
    <div className="flex flex-col mx-auto my-0 w-184">
      <div className="flex justify-center md1:justify-end">
        <MainButton
          className="w-28 h-14 bg-app-green hover:!bg-[#1FF19F]"
          onClick={() => {
            setCreate(true);
            handleCreateLiquidity();
          }}
        >
          Create
        </MainButton>
      </div>
      {pairs.length && !loading && !tokensLoading ? (
        exchangesTable
      ) : loading || tokensLoading ? (
        <div className="text-center">
          <Loader />
        </div>
      ) : (
        <div className="text-center">No Pairs found!</div>
      )}
    </div>
  );
};

export default LiquidityPools;
