export const menuList = [
  {
    id: 1,
    title: "OVERVIEW",
  },
  {
    id: 2,
    title: "PAYMENT METHOD",
  },
  {
    id: 3,
    title: "START ACCOUNT",
  },
  {
    id: 4,
    title: "GAMING ACCOUNT",
  },
  {
    id: 5,
    title: "TRADE ACCOUNT",
  },
  {
    id: 6,
    title: "NFT ACCOUNT",
  },
  {
    id: 7,
    title: "STAKING ACCOUNT",
  },
];
