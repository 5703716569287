import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tokenFrom: {},
  tokenTo: {},
  balance: {
    from: 0,
    to: 0,
  },
  slippage: 0.5,
  isCustomSlippage: false,
  modals: {
    confirm: {
      isOpen: false,
      modalData: {},
    },
    createToken: {
      isOpen: false,
      modalData: {},
    },
    success: {
      isOpen: false,
      modalData: {},
    },
    successMeta: {
      isOpen: false,
      modalData: {},
    },
  },
  limitsuccessmodal: false,
  limitdata: {
    tokenFrom: {},
    tokenTo: {},
    tokenFromMetamask: {},
    tokenToMetamask: {},
    balance: {
      from: 0,
      to: 0,
    },
    price: 0,
    inverseprice: 0,
    txnhash: null,
    tokenfromvalue: null,
    priceMetamask: 0,
    inversepriceMetamask: 0,
    txnhashMetamask: null,
    tokenfromvalueMetamask: null,
  },
  tokenFromSwapMetamask: {},
  tokenToSwapMetamask: {},
};

const swapSlice = createSlice({
  name: "swapMaster",
  initialState,
  reducers: {
    setSwapTokenFrom: (state, action) => {
      state.tokenFrom = action.payload;
    },
    setSwapTokenTo: (state, action) => {
      state.tokenTo = action.payload;
    },
    setSwapTokens: (state, action) => {
      const { tokenFrom, tokenTo } = action.payload;
      const tokenFromWithDefaults = {
        ...tokenFrom,
        decimals: tokenFrom.decimals || 6,
      };

      const tokenToWithDefaults = {
        ...tokenTo,
        decimals: tokenTo.decimals || 6,
      };
      state.tokenFrom = tokenFromWithDefaults;
      state.tokenTo = tokenToWithDefaults;
      // state.tokenFrom = tokenFrom || {};
      // state.tokenTo = tokenTo || {};
    },
    setSwapTokensPolygon: (state, action) => {
      const { tokenFrom, tokenTo } = action.payload;
      const tokenFromWithDefaults = {
        ...tokenFrom,
        decimals: tokenFrom.decimals || 6,
      };

      const tokenToWithDefaults = {
        ...tokenTo,
        decimals: tokenTo.decimals || 6,
      };
      state.tokenFromSwapMetamask = tokenFromWithDefaults;
      state.tokenToSwapMetamask = tokenToWithDefaults;
      // state.tokenFrom = tokenFrom || {};
      // state.tokenTo = tokenTo || {};
    },
    setTokenFromSwapMetamask: (state, action) => {
      state.tokenFromSwapMetamask = action.payload;
    },
    setTokenToSwapMetamask: (state, action) => {
      state.tokenToSwapMetamask = action.payload;
    },
    setSlippage: (state, action) => {
      state.slippage = action.payload;
    },
    setSwapBalances: (state, action) => {
      const { balanceFrom, balanceTo } = action.payload;

      state.balance.from = balanceFrom;
      state.balance.to = balanceTo;
    },
    setIsSwapModalOpen: (state, action) => {
      const { modal, isOpen, modalData } = action.payload;
      const targetModal = state.modals[modal];
      targetModal.isOpen = isOpen;
      targetModal.modalData = modalData || {};

      if (!isOpen) return;

      Object.keys(state.modals).forEach(stateModal => {
        if (stateModal !== modal) state.modals[stateModal].isOpen = false;
      });
    },
    setIsAddTokenModalOpen: (state, action) => {
      const { modal, isOpen, modalData } = action.payload;
      const targetModal = state.modals[modal];
      targetModal.isOpen = isOpen;
      targetModal.modalData = modalData || {};

      if (!isOpen) return;

      Object.keys(state.modals).forEach(stateModal => {
        if (stateModal !== modal) state.modals[stateModal].isOpen = false;
      });
    },
    setlimitSuccessModal: (state, action) => {
      state.limitsuccessmodal = action.payload;
    },
    setLimitTokenFrom: (state, action) => {
      state.limitdata.tokenFrom = action.payload;
    },
    setLimitTokenFromMetamask: (state, action) => {
      state.limitdata.tokenFromMetamask = action.payload;
    },
    setLimitBalances: (state, action) => {
      const { balanceFrom, balanceTo } = action.payload;

      state.limitdata.balance.from = balanceFrom;
      state.limitdata.balance.to = balanceTo;
    },
    setLimitTokenTo: (state, action) => {
      state.limitdata.tokenTo = action.payload;
    },
    setLimitTokenToMetamask: (state, action) => {
      state.limitdata.tokenToMetamask = action.payload;
    },
    setLimitPrice: (state, action) => {
      state.limitdata.price = action.payload.price;
      state.limitdata.inverseprice = action.payload.inverseprice;
      state.limitdata.txnhash = action.payload.txnhash;
      state.limitdata.tokenfromvalue = action.payload.tokenfromvalue;
    },
    setLimitPriceMetamask: (state, action) => {
      state.limitdata.priceMetamask = action.payload.priceMetamask;
      state.limitdata.inversepriceMetamask = action.payload.inversepriceMetamask;
      state.limitdata.txnhashMetamask = action.payload.txnhashMetamask;
      state.limitdata.values = action.payload.values;
      state.limitdata.tokenfromvalueMetamask = action.payload.tokenfromvalueMetamask;
    },
    setIsCustomSlippage: (state, action) => {
      state.isCustomSlippage = action.payload;
    },
    clearSwapState: () => initialState,
  },
});

export const {
  setSwapTokenFrom,
  setSwapTokenTo,
  setSwapTokens,
  setSwapBalances,
  setIsSwapModalOpen,
  clearSwapState,
  setlimitSuccessModal,
  setLimitTokenFrom,
  setLimitTokenTo,
  setLimitBalances,
  setLimitPrice,
  setSlippage,
  setIsCustomSlippage,
  setTokenFromSwapMetamask,
  setTokenToSwapMetamask,
  setLimitTokenToMetamask,
  setLimitTokenFromMetamask,
  setLimitPriceMetamask,
  setIsAddTokenModalOpen,
  setSwapTokensPolygon,
} = swapSlice.actions;

export default swapSlice.reducer;
