function hexToDecimal(hex) {
  return BigInt(`0x${hex}`);
}

function parseHexData(hexString) {
  // Remove the leading '0x' if present
  if (hexString.startsWith("0x")) {
    hexString = hexString.slice(2);
  }

  // Each segment is 64 characters (32 bytes)
  const segmentLength = 64;
  const segments = [];

  // Split the hex string into segments
  for (let i = 0; i < hexString.length; i += segmentLength) {
    segments.push(hexString.slice(i, i + segmentLength));
  }

  // Extract liquidity, amount0, and amount1
  const liquidityHex = segments[0];
  const amount0Hex = segments[1];
  const amount1Hex = segments[2];

  // Convert hex to decimal
  const liquidity = hexToDecimal(liquidityHex).toString();
  const amount0 = hexToDecimal(amount0Hex).toString();
  const amount1 = hexToDecimal(amount1Hex).toString();

  return {
    liquidity: liquidity,
    amount0: amount0,
    amount1: amount1,
  };
}

export default parseHexData;
