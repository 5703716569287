/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";

// Components
import { MainButton } from "../../../components/Button/MainButton";
import univ3prices from "@thanpolas/univ3prices";

// Utils
import { useEffect, useMemo, useState } from "react";
import { changeSwapDirectionPolygon } from "./utils";

// Actions
import SlippageModal from "../../../pages/SwapMaster/Swap/SlippageModal.js";
// Icons
import { ethers } from "ethers";
import { SwapDirectionIcon } from "../icons/SwapDirectionIcon";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
// Constants
import TokenDropdownMeta from "../../../components/DropDown/TokenDropDownMeta.jsx";
import Loader from "../../../components/Loader/Loader.js";
import { LineIcon } from "../../../components/TokenSelectInput/icons/LineIcon.js";
import useContracts from "../../../hooks/useContracts.js";
import usePairs from "../../../hooks/usePairs.js";
import useTokens from "../../../hooks/useTokens.js";
import useWallet from "../../../hooks/useWallet.js";
import ERC20ABI from "../../../ContractABI/ERC20.json";
import {
  setIsSwapModalOpen,
  setTokenFromSwapMetamask,
  setTokenToSwapMetamask,
} from "../../../store/reducers/SwapMaster/swapSlice";

const SwapForm = () => {
  const dispatch = useDispatch();
  const tokenFrom = useSelector(s => s.swap.tokenFromSwapMetamask);
  const tokenTo = useSelector(s => s.swap.tokenToSwapMetamask);
  const slippagepercentage = useSelector(s => s.swap.slippage);
  const [slippage, setSlippage] = useState(false);
  const [to, setTo] = useState();
  const [from, setFrom] = useState("");
  const [error, setError] = useState("");
  const [priceFrom, setPriceFrom] = useState(0);
  const [priceTo, setPriceTo] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { tokens, loading: tokensLoading } = useTokens();
  const { addresses, contracts } = useContracts();
  const { walletAddress, gasPrice, signer } = useWallet();
  const { pairs, loading, providedLoading } = usePairs();
  const [priceLoading, setPriceLoading] = useState(false);
  const pair = useMemo(() => {
    return pairs.find(
      p => p?.tokens?.includes(tokenFrom?.address) && p?.tokens?.includes(tokenTo?.address),
    );
  }, [pairs, tokenFrom?.address, tokenTo?.address, providedLoading, loading]);

  const isPoolExists = useMemo(() => {
    return !!pair && loading === false && providedLoading === false && pair?.liquidity > 0;
  }, [pair]);

  useEffect(() => {
    const fetchData = async () => {
      if (!tokens.length || tokensLoading) return;

      if (!tokenFrom?.address) {
        dispatch(setTokenFromSwapMetamask(tokens[0]));
      }

      if (!tokenTo?.address) {
        dispatch(setTokenToSwapMetamask(tokens[1]));
      }

      formulateToFrom(from);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress, tokensLoading, loading, providedLoading, tokenFrom, tokenTo]);
  // console.log(tokenFrom, "CHECK");

  async function checkAndFetchTokenBalance() {
    const contract = new ethers.Contract(
      "0x351BB7AC055ED18e40EB6Bd8779E572ec4c7b5B6",
      ERC20ABI,
      signer,
    );
    const contract1 = new ethers.Contract(
      "0x4356a8185b6845bE94a10b26547438289d1C5F33",
      ERC20ABI,
      signer,
    );
    // console.log("Running");

    try {
      const tokenBalance = walletAddress ? await contract.balanceOf(walletAddress) : "0";
      const formattedBalance = parseFloat(ethers.utils.formatEther(tokenBalance));
      // console.log(formattedBalance, "TOKEN1");
      dispatch(
        setTokenFromSwapMetamask({
          id: "66448230dfd3181cfa12a5a5",
          address: "0x351BB7AC055ED18e40EB6Bd8779E572ec4c7b5B6",
          tokenName: "Swap Sage",
          symbol: "SS",
          icon: "https://api.pixpel.io/api/v1/image/SwapSage.png",
          contract,
          tokenBalance: formattedBalance,
        }),
      );
      const tokenBalance1 = walletAddress ? await contract1.balanceOf(walletAddress) : "0";
      const formattedBalance1 = parseFloat(ethers.utils.formatEther(tokenBalance1));
      // console.log(formattedBalance1, "TOKEN2");
      dispatch(
        setTokenToSwapMetamask({
          id: "66448237dfd3181cfa12a5a9",
          address: "0x4356a8185b6845bE94a10b26547438289d1C5F33",
          tokenName: "Crypto Hub",
          symbol: "CTH",
          icon: "https://api.pixpel.io/api/v1/image/Crypto Hub.png",
          contract: contract1,
          tokenBalance: formattedBalance1,
        }),
      );
    } catch (error) {
      console.error("Error fetching token balance for contract 1:", error);
    }
  }

  // console.log(reCheck);
  // useEffect(() => {
  //   if (from > 0) {
  //     formulateToFrom(to, true);
  //   }
  // }, [reCheck]);
  useEffect(() => {
    checkAndFetchTokenBalance();
  }, [walletAddress]);

  async function formulateToFrom(amount, isTo, callback) {
    try {
      if (!loading && !providedLoading && !isPoolExists) {
        setPriceLoading(false);

        return;
      }

      if (parseFloat(amount) === 0) {
        setError({ message: "From field amount should be greater than 0" });
      }

      const { tokensOwed0, tokensOwed1, sqrtPriceX96, tokens } = pair;

      if (!loading && !providedLoading && (!tokensOwed0 || !tokensOwed1)) {
        setError({ message: "No Tokens available in the pool" });
        setPriceLoading(false);

        if (!isTo) {
          setTo(0);
        } else {
          setFrom(0);
        }

        return;
      }

      const price = univ3prices([18, 18], sqrtPriceX96).toAuto({
        reverse: tokenFrom?.address === tokens?.[1],
      });

      const newPrice = Number(price).toLocaleString().replace(",", "");
      // Calculate "to" amount based on LP amount
      const targetToAmount = !isTo
        ? parseFloat(amount) / parseFloat(newPrice)
        : parseFloat(amount) * parseFloat(newPrice);

      if (!isNaN(targetToAmount) && targetToAmount > 0) {
        setPriceLoading(false);
      } else if (parseFloat(newPrice) <= 0) {
        setPriceLoading(false);

        return;
      } else {
        return;
      }

      if (callback) {
        callback(targetToAmount.toFixed(6) || 0);

        return;
      }

      if (!isTo) {
        if (parseFloat(amount) > tokenFrom?.tokenBalance) {
          setError({ message: `Not enough ${tokenFrom?.symbol} balance` });
        }

        setTo(targetToAmount.toFixed(6) || 0);
      } else {
        if (targetToAmount > tokenFrom?.tokenBalance) {
          setError({ message: `Not enough ${tokenFrom?.symbol} balance` });
        }

        setFrom(targetToAmount.toFixed(6) || 0);
      }
    } catch (error) {
      console.error(error, "ERROR");
    }
  }

  const handleFromChange = e => {
    const value = e.target.value;
    setError("");

    if (isNaN(parseFloat(value))) {
      setError({ message: "From is a required field" });
    }

    if (parseFloat(value) <= 0) {
      setError({ message: "From is a required field" });
    }

    if (parseFloat(value) > tokenFrom?.tokenBalance) {
      setError({ message: `Not enough balance` });
    }

    value && setPriceLoading(true);
    setFrom(value.slice(0, 18));
    // setFrom(value);
    value && formulateToFrom(value);
  };

  const handleToChange = e => {
    const value = e.target.value;
    setError("");
    // console.log(value, "CHECK VALUE");

    if (isNaN(parseFloat(value))) {
      setError({ message: "From felid amount should be greater than zero" });
    }

    if (parseFloat(value) <= 0) {
      setError({ message: "From is a required field" });
    }

    if (parseFloat(value) > tokenTo?.tokenBalance) {
      setError({ message: `Not enough balance` });
    }

    value && setPriceLoading(true);
    setTo(value.slice(0, 18));
    value && formulateToFrom(value, true);
  };

  useEffect(() => {
    setPriceFrom(0);
    setPriceTo(0);

    if (isPoolExists) {
      if (
        !isNaN(parseFloat(to)) &&
        parseFloat(to) >
          (tokenFrom?.address === pair?.tokens[0] ? pair?.tokensOwed1 : pair?.tokensOwed0)
      ) {
        setError({ message: `Not enough ${tokenTo?.symbol} balance in the pool` });
      }

      if (
        !isNaN(parseFloat(from)) &&
        parseFloat(from) > 0 &&
        (isNaN(parseFloat(to) <= 0) || parseFloat(to) <= 0)
      ) {
        formulateToFrom(from);
      } else if (
        !isNaN(parseFloat(to)) &&
        parseFloat(to) > 0 &&
        (isNaN(parseFloat(from) <= 0) || parseFloat(from) <= 0)
      ) {
        formulateToFrom(to, true);
      }

      formulateToFrom("1", true, amount => {
        setPriceFrom(amount);
      });
      formulateToFrom("1", false, amount => {
        setPriceTo(amount);
      });
    }
  }, [pair, from, to]);

  const handleSwap = async () => {
    try {
      setIsSubmitting(true);
      const amountToSwap = from;
      // ERC20 Instance
      const approveTx = await tokenFrom?.contract?.approve(
        addresses.pixpelSwap,
        ethers.utils.parseEther(amountToSwap.toString()),
      );
      await approveTx.wait();
      const deadline = Math.floor(Date.now() / 1000) + 60 * 10;
      const params = {
        deadline,
        recipient: walletAddress,
        tokenIn: tokenFrom?.address,
        tokenOut: tokenTo?.address,
        fee: 500,
        amountIn: ethers.utils.parseEther(from.toString()).toString(),
        amountOutMinimum: "0",
        sqrtPriceLimitX96: 0,
      };
      const tx = await contracts.pixpelSwap.exactInputSingle(params, {
        gasPrice: gasPrice,
        gasLimit: "3000000",
      });

      const result = await tx.wait();

      dispatch(
        setIsSwapModalOpen({
          modal: "successMeta",
          isOpen: true,
          modalData: {
            values: {
              lp: "0",
              from,
              to,
            },
            fromPerToAmount: from / to,
            toPerFromAmount: to / from,
            txnHash: result?.transactionHash,
          },
        }),
      );
    } catch (error) {
      console.error("Transaction failed", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  function handleSlippage() {
    setSlippage(prev => !prev);
  }

  const handleSwapDirection = () => {
    dispatch(changeSwapDirectionPolygon());
  };

  const prevSelected = [tokenFrom?.address?.toLowerCase(), tokenTo?.address?.toLowerCase()];

  const validateValues = from => {
    setError("");

    const fromAmount = tokenFrom?.tokenBalance;

    if (pair?.liquidity <= 0) setError({ message: "No filled Pool available" });

    if (isNaN(fromAmount) || isNaN(from)) setError({ message: "From must be a positive number" });

    if (fromAmount <= 0) setError({ message: "From is a required field" });

    if (from <= 0) setError({ message: "From is a required field" });

    if (from > fromAmount) setError({ message: `Not enough ${tokenFrom?.symbol} balance` });

    if (Number(pair?.contract?.address) === 0) {
      setError({ message: "Pair doesn't exists!", type: "required" });
    }

    if (
      isPoolExists &&
      !isNaN(parseFloat(to)) &&
      parseFloat(to) >
        (tokenFrom?.address === pair?.tokens[0] ? pair?.tokensOwed1 : pair?.tokensOwed0)
    ) {
      setError({ message: `Not enough ${tokenTo?.symbol} balance in the pool` });
    }
  };

  // console.log(tokenFrom, tokenTo, "TOKEN FROM");

  const handleMaxClick = e => {
    setError("");

    if (e.target.name && e.target.name === "to") {
      setTo(tokenTo?.tokenBalance);
      setPriceLoading(true);
      formulateToFrom(tokenTo?.tokenBalance, true);
      validateValues(tokenTo?.tokenBalance, true);
    } else {
      setFrom(tokenFrom?.tokenBalance);
      setPriceLoading(true);
      formulateToFrom(tokenFrom?.tokenBalance);
      validateValues(tokenFrom?.tokenBalance);
    }
  };

  // console.log(to, "Check");

  return (
    <>
      <form
        className={`flex flex-col relative rounded-md w-full lg:min-w-[35vw] 2xl:w-155 bg-app-black sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px]`}
      >
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">From</div>
          <div className="font-normal text-gray-400">
            Balance: {!tokenFrom?.tokenBalance ? "0.00" : tokenFrom?.tokenBalance}
          </div>
        </div>
        <div className="flex items-center w-full rounded-lg h-16 bg-[#37404c]">
          {tokens.length > 0 && (
            <div className="flex items-center space-x-16">
              <TokenDropdownMeta
                type="from"
                initialContent={tokenFrom}
                contentList={tokens}
                backgroundColor="bg-[#37404c]"
                prevSelected={prevSelected}
                disabled={isSubmitting}
              />
              <LineIcon className="" />
            </div>
          )}
          <div className="flex items-center w-10/12 justify-between pr-2">
            <input
              type="number"
              placeholder={from ? from : 0}
              value={from}
              step="1"
              min={1}
              disabled={tokensLoading || loading || isSubmitting}
              onChange={handleFromChange}
              onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              className="text-white disabled:cursor-not-allowed bg-transparent px-2 w-1/2"
              style={{ outline: "none" }}
              maxLength={8}
              max={8}
            />
            <button
              type="button"
              className="flex mx-2 text-app-blue font-medium"
              onClick={handleMaxClick}
            >
              MAX
            </button>
          </div>
        </div>
        <div className="flex justify-center w-full mt-5">
          <div
            className="flex items-center justify-center rounded-full cursor-pointer full bg-app-black-button hover:bg-[#717A8B]"
            style={{ marginBottom: "10px", width: "53px", height: "53px" }}
            onClick={handleSwapDirection}
            title="Revert swap direction"
          >
            <SwapDirectionIcon />
          </div>
        </div>
        <div className="flex flex-col justify-between text-lg 1xs:flex-row">
          <div className="font-semibold">To</div>
          <div className="font-normal text-gray-400">
            Balance: {tokenTo?.tokenBalance ? tokenTo?.tokenBalance : "0"}
          </div>
        </div>

        <div className="flex items-center w-full rounded-lg h-16 bg-[#37404c]">
          {tokens.length > 0 && (
            <div className="flex items-center space-x-16">
              <TokenDropdownMeta
                type="to"
                initialContent={tokenTo}
                contentList={tokens}
                backgroundColor="bg-[#37404c]"
                prevSelected={prevSelected}
                disabled={isSubmitting}
              />
              <LineIcon className="" />
            </div>
          )}
          <div className="flex items-center w-10/12">
            <input
              type="number"
              placeholder={to}
              disabled={tokensLoading || loading || isSubmitting}
              step="1"
              value={from ? (parseFloat(Number(to).toFixed(1)) === 0 ? 0 : to) : 0}
              min={-1}
              maxLength={8}
              onChange={handleToChange}
              className="text-white bg-transparent disabled:cursor-not-allowed px-2 w-5/6"
              style={{ outline: "none" }}
            />
            {priceLoading ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "grey" }} spin />} />
            ) : (
              ""
            )}
            <button
              type="button"
              className="flex mx-2 text-app-blue font-medium"
              onClick={handleMaxClick}
              name="to"
            >
              MAX
            </button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-5 text-sm border-b-2 border-app-black">
          <div className="flex flex-row justify-between">
            <div>Price</div>
            <div>{`1 ${tokenFrom?.symbol} = ${
              (tokenFrom?.address === pair?.tokens?.[0] ? priceTo : priceFrom) || 0
            } ${tokenTo?.symbol}`}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>Inverse Price</div>
            <div>{`1 ${tokenTo?.symbol} = ${
              (tokenFrom?.address !== pair?.tokens?.[0] ? priceTo : priceFrom) || 0
            } ${tokenFrom?.symbol}`}</div>
          </div>
          <div className="flex flex-row justify-between">
            <div>Pool fee</div>
            <div>0.01%</div>
          </div>
          <div className="flex flex-row items-center justify-between">
            <div>You will receive</div>
            <div className="text-2xl text-app-blue">
              {" "}
              {to && !isNaN(Number(to) * 0.9999) ? (Number(to) * 0.9999).toFixed(8) : "0.00000000"}
            </div>
          </div>
          <div className="flex flex-row items-center justify-between mb-4">
            <div>You will receive minimum</div>
            <div className="text-2xl text-app-blue">
              {" "}
              {to && !isNaN(Number(to) * 0.9999)
                ? (Number(to) * 0.9999 - Number(to) * 0.9999 * (slippagepercentage / 100)).toFixed(
                    8,
                  )
                : "0.00000000"}
            </div>
          </div>
        </div>
        <MainButton
          disabled={slippage}
          onClick={() => handleSlippage()}
          className="p-4 h-16 mt-5 bg-[#37404C] text-lg disabled:bg-[#717A8B] hover:!bg-[#717A8B] "
        >
          Slippage Tolerance
        </MainButton>
        <MainButton
          onClick={handleSwap}
          disabled={
            isSubmitting ||
            !from ||
            !tokenFrom ||
            slippage ||
            !to ||
            !!error?.message ||
            !isPoolExists ||
            loading ||
            providedLoading
          }
          className="p-4 h-16 mt-5 bg-app-blue text-lg disabled:cursor-not-allowed disabled:bg-app-black-button hover:bg-[#50D0FB] "
        >
          {isSubmitting && <Loader size="md" />}{" "}
          {loading || providedLoading
            ? "Loading Pools"
            : !isPoolExists
            ? "No Pool in the Liquidity"
            : error?.message
            ? error?.message
            : "Confirm"}
        </MainButton>
        {!walletAddress && (
          <div className="absolute top-0 right-0 bottom-0 left-0 bg-black bg-opacity-25 flex items-start justify-center">
            <p className="text-lg pt-4">No filled pools found</p>
          </div>
        )}
      </form>
      {slippage ? <SlippageModal closeModal={handleSlippage} /> : ""}
    </>
  );
};

export default SwapForm;
