/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import univ3prices from "@thanpolas/univ3prices";
import PairABI from "../../../ContractABI/Pool.json";
import { LoadingOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Spin } from "antd";
import { ethers, BigNumber } from "ethers";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
// Components
// import { MainButton } from "../../../components/Button/MainButton";

// Actions
import {
  setIsLiquidityModalOpen,
  setTxnhash,
} from "../../../store/reducers/SwapMaster/liquiditySlice";

// Utils
import { getShortTokenName } from "../../../utils/format";
import { getMaxCcdAmount } from "../Swap/utils";
// import { capitalizeString } from "../../../utils/common";
import { validationSchema } from "./validationSchema";

// Constants
import { message } from "antd";
import { useEffect, useMemo, useState } from "react";
import TokenDropdownMeta from "../../../components/DropDown/TokenDropDownMeta";
import Loader from "../../../components/Loader/Loader.js";
import { LineIcon } from "../../../components/TokenSelectInput/icons/LineIcon.js";
import useContracts from "../../../hooks/useContracts.js";
import usePairs from "../../../hooks/usePairs.js";
import useTokens from "../../../hooks/useTokens";
import useWallet from "../../../hooks/useWallet.js";
import {
  setTokenFromSwapMetamask,
  setTokenToSwapMetamask,
} from "../../../store/reducers/SwapMaster/swapSlice";
import {
  createPair,
  createPairData,
  updatePair,
  updatePairData,
} from "../../../utils/polygonFirebase.js";
import { LIQUIDITY_FORM_FIELDS, LIQUIDITY_FORM_ID } from "./constants";
import parseHexData from "../../../utils/hex.js";
import bn from "bignumber.js";
bn.config({ EXPONENTIAL_AT: 999999, DECIMAL_PLACES: 40 });

const LiquidityMetamask = ({ isUnstakeMode, create, poolToken }) => {
  const [to, setTo] = useState("");
  const [lp, setLp] = useState("");
  const [from, setFrom] = useState("");
  const isShowModal = useSelector(s => s.swap.modals.createToken.isOpen);
  const dispatch = useDispatch();
  const tokenFrom = useSelector(s => s.swap.tokenFromSwapMetamask);
  const tokenTo = useSelector(s => s.swap.tokenToSwapMetamask);
  const [isSubmitting, setisSubmitting] = useState(false);
  const [priceFrom, setPriceFrom] = useState(0);
  const [priceTo, setPriceTo] = useState(0);
  const [priceLoading, setPriceLoading] = useState(false);
  const { tokens, loading: tokensLoading } = useTokens();
  const { addresses, contracts } = useContracts();
  const { walletAddress, gasPrice } = useWallet();
  const { pairs, refetchPairs, loading, providedLoading } = usePairs();
  const pair = useMemo(() => {
    return pairs.find(
      p => p?.tokens?.includes(tokenFrom?.address) && p?.tokens?.includes(tokenTo?.address),
    );
  }, [pairs, tokenFrom?.address, tokenTo?.address, providedLoading, loading]);

  const isPoolExists = useMemo(() => {
    return !!pair && loading === false && providedLoading === false;
  }, [pair]);

  const balanceLp = isUnstakeMode ? pair?.liquidityProvided : 0;
  const methods = useForm({
    defaultValues: {
      ...(isUnstakeMode && { [LIQUIDITY_FORM_FIELDS.lp]: 0 }),
      [LIQUIDITY_FORM_FIELDS.from]: isUnstakeMode ? 0 : "",
      [LIQUIDITY_FORM_FIELDS.to]: isUnstakeMode ? 0 : "",
    },
    resolver: yupResolver(validationSchema),
    context: {
      balanceFrom: getMaxCcdAmount(tokenFrom?.tokenBalance),
      balanceTo: getMaxCcdAmount(tokenTo?.tokenBalance),
      balanceLp,
      isUnstakeMode,
      maxFromDecimals: tokenFrom?.decimals,
      maxToDecimals: tokenTo?.decimals,
    },
    mode: "all",
  });
  const {
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = methods;

  const getData = async () => {
    if (!tokens.length || tokensLoading || loading) return;

    if (!tokenFrom?.address || !tokenTo?.address) {
      await dispatch(setTokenFromSwapMetamask(tokens[0]));
      await dispatch(setTokenToSwapMetamask(tokens[1]));
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowModal, tokensLoading, loading]);

  async function formulateToFrom(amount, isTo, callback) {
    try {
      clearErrors();

      if (!loading && !providedLoading && !isPoolExists) {
        setPriceLoading(false);

        return;
      }

      const { tokensOwed0, tokensOwed1, sqrtPriceX96, tokens } = pair;

      if (!loading && !providedLoading && isUnstakeMode && (!tokensOwed0 || !tokensOwed1)) {
        setError("custom", { message: "No Tokens available in the pool" });
        setPriceLoading(false);

        return;
      }

      if (!loading && !providedLoading && !isUnstakeMode && (!tokensOwed0 || !tokensOwed1)) {
        setPriceLoading(false);

        return;
      }

      const price = univ3prices([18, 18], sqrtPriceX96).toAuto({
        reverse: tokenFrom?.address === tokens?.[1],
      });

      const newPrice = Number(price).toLocaleString().replace(",", "");

      // Calculate "to" amount based on LP amount
      const targetToAmount = isTo
        ? parseFloat(amount) / parseFloat(newPrice)
        : parseFloat(amount) * parseFloat(newPrice);

      if (
        !isNaN(targetToAmount) &&
        targetToAmount > 0 &&
        targetToAmount.toString() !== "Infinity"
      ) {
        setPriceLoading(false);
      } else if (parseFloat(newPrice) <= 0) {
        setPriceLoading(false);

        return;
      } else {
        return;
      }

      if (callback) {
        callback(targetToAmount.toFixed(6) || 0);

        return;
      }

      if (!isTo) {
        setTo(targetToAmount.toFixed(6) || 0);
      } else {
        setFrom(targetToAmount.toFixed(6) || 0);
      }
    } catch (error) {
      console.error(error, "ERROR");
    }
  }

  useEffect(() => {
    if (
      !isNaN(parseFloat(from)) &&
      parseFloat(from) > 0 &&
      (isNaN(parseFloat(to) <= 0) || parseFloat(to) <= 0)
    ) {
      formulateToFrom(from);
    } else if (
      !isNaN(parseFloat(to)) &&
      parseFloat(to) > 0 &&
      (isNaN(parseFloat(from) <= 0) || parseFloat(from) <= 0)
    ) {
      formulateToFrom(to, true);
    }

    setPriceFrom(0);
    setPriceTo(0);

    if (isPoolExists) {
      formulateToFrom("1", true, amount => {
        setPriceFrom(amount);
      });
      formulateToFrom("1", false, amount => {
        setPriceTo(amount);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pair]);

  function encodePriceSqrt(reserve1, reserve0) {
    return BigNumber.from(
      new bn(reserve1.toString())
        .div(reserve0.toString())
        .sqrt()
        .multipliedBy(new bn(2).pow(96))
        .integerValue(3)
        .toString(),
    );
  }

  function calculateUniswapTicks(currentTick, tickSpacing, numTicks) {
    /**
     * Calculate the upper and lower ticks based on the current tick for Uniswap V3.
     *
     * Parameters:
     * currentTick (number): The current tick value.
     * tickSpacing (number): The spacing between ticks.
     * numTicks (number): The number of ticks to calculate the upper and lower ticks.
     *
     * Returns:
     * object: An object with 'upperTick' and 'lowerTick'.
     */

    const upperTick = currentTick + tickSpacing * numTicks;
    const lowerTick = currentTick - tickSpacing * numTicks;

    return {
      tickUpper: upperTick,
      tickLower: lowerTick,
    };
  }

  const handleAddLiquidity = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const amountADesired = ethers.utils.parseEther(to.toString());
      const amountBDesired = ethers.utils.parseEther(from.toString());

      const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

      let pairAddress = pair?.pairAddress;

      if (!pairAddress) {
        const pool = await contracts.factory.getPool(tokenFrom?.address, tokenTo?.address, 500);

        if (Number(pool) !== 0) {
          pairAddress = pool;
        } else {
          const tx = await contracts.pixpelSwap.createAndInitializePoolIfNecessary(
            Number(tokenFrom?.address) < tokenTo?.address ? tokenFrom?.address : tokenTo?.address,
            Number(tokenFrom?.address) > tokenTo?.address ? tokenFrom?.address : tokenTo?.address,
            500,
            encodePriceSqrt(
              Number(tokenFrom?.address) < tokenTo?.address
                ? Number(to) <= 1
                  ? 1
                  : Number(to)
                : Number(from) <= 1
                ? 1
                : Number(from),
              Number(tokenFrom?.address) > tokenTo?.address
                ? Number(to) <= 1
                  ? 1
                  : Number(to)
                : Number(from) <= 1
                ? 1
                : Number(from),
            ),
            {
              gasPrice: await signer.getGasPrice(),
              gasLimit: "5000000",
            },
          );
          await tx.wait();
          const pool = await contracts.factory.getPool(tokenFrom?.address, tokenTo?.address, 500);
          pairAddress = pool;
        }

        if (!pair) {
          const { contract: c1, tokenBalance: tokenABalance, ...tokenA } = tokenFrom;
          const { contract: c2, tokenBalance: tokenBBalance, ...tokenB } = tokenTo;
          const d = Date.now();
          await createPair({
            pairAddress: pairAddress,
            tokenA: tokenFrom?.address < tokenTo?.address ? tokenA : tokenB,
            tokenB: tokenFrom?.address > tokenTo?.address ? tokenA : tokenB,
            createdAt: `${d}`,
          });
          await refetchPairs();
        }
      }

      let result;

      await tokenFrom?.contract.approve(addresses.pixpelSwap, amountADesired.toString());
      await tokenTo?.contract.approve(addresses.pixpelSwap, amountBDesired.toString());

      if (!pair || !pair?.tokenId) {
        const pairContract = new ethers.Contract(pairAddress, PairABI, signer);
        const tickSpacing = await pairContract.tickSpacing();
        const { tick } = await pairContract.slot0();
        const { tickLower, tickUpper } = await calculateUniswapTicks(
          Number(tick),
          Number(tickSpacing),
          20,
        );

        const params = {
          token0: tokenFrom?.address < tokenTo?.address ? tokenFrom?.address : tokenTo?.address,
          token1: tokenFrom?.address > tokenTo?.address ? tokenFrom?.address : tokenTo?.address,
          fee: 500,
          tickLower,
          tickUpper,
          amount0Desired:
            tokenFrom?.address < tokenTo?.address
              ? amountADesired.toString()
              : amountBDesired.toString(),
          amount1Desired:
            tokenFrom?.address > tokenTo?.address
              ? amountADesired.toString()
              : amountBDesired.toString(),
          amount0Min: 0,
          amount1Min: 0,
          recipient: walletAddress,
          deadline,
        };

        const tx = await contracts.pixpelSwap.createLiquidity(params, {
          gasPrice: await signer.getGasPrice(),
          gasLimit: "5000000",
        });

        result = await tx.wait();
        dispatch(setTxnhash(result?.transactionHash));

        let amount = 0;

        const log = result.logs[result.logs.length - 2];

        const { liquidity } = await parseHexData(log.data);

        amount = parseFloat(ethers.utils.formatEther(liquidity));
        await createPairData({
          pairAddress: pairAddress,
          tokensOwed0: 0,
          tokensOwed1: 0,
          liquidity: 0,
          owner: walletAddress.toLowerCase(),
          tokenId: Number(log.topics[1]),
        });

        await refetchPairs();

        return amount;
      } else {
        const params = {
          tokenId: pair?.tokenId,
          amount0Desired:
            tokenFrom?.address === pair?.tokens[0]
              ? amountADesired.toString()
              : amountBDesired.toString(),
          amount1Desired:
            tokenFrom?.address !== pair?.tokens[0]
              ? amountADesired.toString()
              : amountBDesired.toString(),
          amount0Min: 0,
          amount1Min: 0,
          deadline,
        };

        const tx = await contracts.pixpelSwap.addLiquidity(params, {
          gasPrice: await signer.getGasPrice(),
          gasLimit: "5000000",
        });

        result = await tx.wait();
        dispatch(setTxnhash(result?.transactionHash));

        let amount = 0;

        const log = result.logs[3];
        const { liquidity } = await parseHexData(log.data);

        amount = parseFloat(ethers.utils.formatEther(liquidity));

        await refetchPairs();

        return amount;
      }
    } catch (error) {
      console.error("Transaction failed", error);
      throw new Error(error?.message);
    }
  };

  const handleRemoveLiquidity = async () => {
    try {
      await contracts.liquidityNFT.approve(addresses.positionManager, pair?.tokenId?.toString());

      if (parseFloat(lp) === balanceLp) {
        const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

        const params = {
          tokenId: pair.tokenId?.toString(),
          liquidity: pair?.liquidityEth,
          amount0Min: 0,
          amount1Min: 0,
          deadline,
        };

        const { tokensOwed0: tokensOwed0Eth, tokensOwed1: tokensOwed1Eth } =
          await contracts.positionManager.positions(pair?.tokenId?.toString());

        const collectParams = {
          tokenId: pair.tokenId?.toString(),
          amount0Max: tokensOwed0Eth,
          amount1Max: tokensOwed1Eth,
          recipient: walletAddress,
        };

        const collectTx = await contracts.removeAllLiquidity.collect(params, collectParams, {
          gasPrice,
          gasLimit: "5000000",
        });

        const result = await collectTx.wait();

        dispatch(setTxnhash(result?.transactionHash));
      } else {
        const deadline = Math.floor(Date.now() / 1000) + 60 * 10;

        const params = {
          tokenId: pair.tokenId?.toString(),
          liquidity: ethers.utils.parseEther(lp.toString()),
          amount0Min: 0,
          amount1Min: 0,
          deadline,
        };

        const tx = await contracts.pixpelSwap.removeLiquidity(params, {
          gasPrice,
          gasLimit: "5000000",
        });

        const result = await tx.wait();
        dispatch(setTxnhash(result?.transactionHash));
      }
    } catch (error) {
      console.error("Transaction failed", error);
      throw new Error(error?.message);
    }
  };

  const submitForm = async () => {
    try {
      setisSubmitting(true);

      if (isUnstakeMode) {
        await handleRemoveLiquidity();
        dispatch(
          setIsLiquidityModalOpen({
            modal: "createMetamask",
            isOpen: true,
            modalData: {
              values: {
                lp: parseFloat(lp),
                from,
                to,
              },
              poolShare,
              lpBalance: balanceLp - parseFloat(lp),
              isUnstakeMode,
              isFilledPool,
              fromPerToAmount,
              toPerFromAmount,
            },
          }),
        );
      } else {
        const addedLiquidity = await handleAddLiquidity();

        dispatch(
          setIsLiquidityModalOpen({
            modal: "createMetamask",
            isOpen: true,
            modalData: {
              values: {
                lp: balanceLp ? balanceLp + addedLiquidity : addedLiquidity,
                from,
                to,
              },
              poolShare,
              lpBalance: balanceLp - parseFloat(lp),
              isUnstakeMode,
              isFilledPool,
              fromPerToAmount,
              toPerFromAmount,
            },
          }),
        );
      }
    } catch (error) {
      console.error(error);
      message.error("Transaction failed");
    } finally {
      setisSubmitting(false);
    }
  };

  // console.log(encodePriceSqrt(1, 1));

  // const values = watch();
  const isFilledPool = pair?.liquidityProvided > 0;
  const fromPerToAmount = priceFrom;
  const toPerFromAmount = priceTo;
  const poolShare = isFilledPool
    ? ((100 * parseFloat(from) || 0) / (pair?.liquidityProvided + parseFloat(from) || 0)).toFixed(6)
    : "100";

  const validateValues = (lp, from, to) => {
    clearErrors();
    const fromAmount = tokenFrom?.tokenBalance;
    const toAmount = tokenTo?.tokenBalance;
    const lpAmount = balanceLp;

    let errors = [];

    if (isUnstakeMode) {
      if (isNaN(lpAmount) || isNaN(lp)) {
        errors.push("Lp must be a positive number");
      }

      if (lp <= 0) {
        errors.push("Lp is a required field");
      }

      if (lp > lpAmount) {
        errors.push("Not enough Lp balance");
      }

      if (!isNaN(lp) && lp > 0) {
        calculateFromTo(lp);
      }
    } else {
      if (from === 0 && to === 0) {
        errors.push("Please enter an amount of tokens greater than zero");
      }

      if (from < 0) {
        errors.push("From must be a positive number");
      }

      if (to < 0) {
        errors.push("To must be a positive number");
      }

      if (from > fromAmount) {
        errors.push(`Not enough ${tokenFrom?.symbol} balance`);
      }

      if (to > toAmount) {
        errors.push(`Not enough ${tokenTo?.symbol} balance`);
      }
    }

    if (errors.length > 0) {
      setError("custom", { message: errors.join(", ") });
    }
  };

  const handleMaxClick = async field => {
    setPriceLoading(true);

    if (field === "to") {
      // Calculate the maximum amount that can be added to the liquidity pool
      // This is a simplified example. You might need to adjust the logic based on your specific requirements and the actual calculation method for determining the maximum amount.
      const maxAmount = tokenFrom?.tokenBalance; // Assuming this is the maximum amount available for 'from'
      setTo(maxAmount || 0); // Update the 'from' field with the calculated maximum amount
      formulateToFrom(maxAmount.toString(), true);
      validateValues(
        parseFloat(lp.toString()),
        parseFloat(from.toString()),
        parseFloat(maxAmount.toString()),
      );
    } else if (field === "from") {
      // Similarly, calculate the maximum amount for the 'to' field
      const maxAmount = tokenTo?.tokenBalance; // Assuming this is the maximum amount available for 'to'
      setFrom(maxAmount || 0); // Update the 'to' field with the calculated maximum amount
      formulateToFrom(maxAmount.toString());
      validateValues(
        parseFloat(lp.toString()),
        parseFloat(maxAmount.toString()),
        parseFloat(to.toString()),
      );
    } else {
      setLp(balanceLp);
      calculateFromTo(balanceLp);
      validateValues(parseFloat(balanceLp.toString()), parseFloat(from), parseFloat(to.toString()));
    }
  };

  const calculateFromTo = async lpAmount => {
    clearErrors();
    const { tokensOwed0, tokensOwed1, sqrtPriceX96, tickSpacing } = pair;

    if (!loading && !providedLoading && isUnstakeMode && (!tokensOwed0 || !tokensOwed1)) {
      setError("custom", { message: "No Tokens available in the pool" });
      setPriceLoading(false);

      return;
    }

    // Get the reserves for the DAI/WETH Liquidity Pool.
    const [token0Reserves, token1Reserves] = univ3prices.getAmountsForCurrentLiquidity(
      [18, 18],
      ethers.utils.parseEther(lpAmount.toString()), // Current liquidity value of the pool
      sqrtPriceX96, // Current sqrt price value of the pool
      tickSpacing, // the tickSpacing value from the pool
    );
    const targetFromAmount = parseFloat(token1Reserves);
    const targetToAmount = parseFloat(token0Reserves);

    if (!isNaN(targetToAmount) && targetToAmount > 0) {
      setPriceLoading(false);
    } else {
      return;
    }

    setTo(
      tokenFrom?.address === tokens?.[1]
        ? targetFromAmount.toFixed(6)
        : targetToAmount.toFixed(6) || 0,
    );
    setFrom(
      tokenFrom?.address === tokens?.[1]
        ? targetToAmount.toFixed(6)
        : targetFromAmount.toFixed(6) || 0,
    );
  };

  const onSubmit = async values => {
    dispatch(
      setIsLiquidityModalOpen({
        modal: "confirm",
        isOpen: true,
        modalData: {
          fromPerToAmount,
          toPerFromAmount,
          values,
          poolShare,
          isUnstakeMode,
          isFilledPool,
        },
      }),
    );
  };

  useEffect(() => {
    if (!isPoolExists) {
      if (from !== to) {
        setError("custom", {
          message: `Amount of ${tokenFrom?.symbol} and ${tokenTo?.symbol} should be equal !!`,
        });
      }
    }
  }, [from, to, isPoolExists, tokenFrom, tokenTo]);

  const prevSelected = [tokenFrom?.address?.toLowerCase(), tokenTo?.address?.toLowerCase()];
  const k = {
    address: "0x60826F05e4D5c6be2fC1e61e4f1e515b6f7A9Fc6",
    icon: "https://api.pixpel.io/api/v1/image/Token1.png",
    id: "66448230dfd3181cfa12a5a5",
    symbol: "LP",
    tokenName: "LP",
  };

  return (
    <div className="flex flex-col bg-app-black rounded-xl sm:p-[50px] xs:p-[40px] 1xs:p-[30px] 2xs:p-[20px] p-[10px] w-full md1:w-155">
      <div className="flex justify-center">
        <div className="text-xl font-semibold">
          {isUnstakeMode ? "Remove" : create ? "Create" : "Add"} liquidity
        </div>
      </div>
      <div className="flex justify-center mt-2 mb-6">
        <div className="text-sm font-medium text-slate-500">
          {isUnstakeMode ? "Remove" : "Add"} liquidity to receive
          {isUnstakeMode ? " tokens" : " LP token"}
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} id={LIQUIDITY_FORM_ID}>
        {isUnstakeMode ? (
          <>
            <div className="flex flex-row justify-end mt-1 text-sm mb-4">
              <div className="font-normal text-gray-400">Balance: {balanceLp}</div>
            </div>
            <div className="flex w-full justify-between p-2 mb-4 rounded-lg bg-[#37404c]">
              <div className="w-1/4">
                <TokenDropdownMeta
                  type="Lp"
                  initialContent={k}
                  contentList={[]}
                  backgroundColor="bg-[#37404c]"
                  disabled={isSubmitting}
                />
              </div>
              <div className="flex items-center w-10/12 justify-between pr-2">
                <input
                  type="number"
                  step="1"
                  placeholder={lp}
                  value={lp}
                  min={0}
                  maxLength={8}
                  onChange={async e => {
                    clearErrors();
                    setPriceLoading(true);
                    setLp(e.target.value);

                    validateValues(
                      typeof e.target.value === "string"
                        ? parseFloat(e.target.value)
                        : e.target.value,
                      parseFloat(from),
                      parseFloat(to),
                    );
                  }}
                  className="text-white bg-transparent px-2 w-[70%]"
                  style={{ outline: "none" }}
                />
                <button
                  type="button"
                  className="flex mx-2 text-app-blue font-medium"
                  onClick={() => handleMaxClick("lp")}
                >
                  MAX
                </button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
        <div className="flex flex-row justify-end mt-4 text-sm">
          <div className="font-normal text-gray-400">
            Balance: {tokenFrom?.tokenBalance ? tokenFrom?.tokenBalance : "0.0"}
          </div>
        </div>
        <div className="flex w-full min-h-16 justify-between mb-4 rounded-lg bg-[#37404c]">
          {tokens.length > 0 && (
            <div className="flex items-center space-x-20">
              <TokenDropdownMeta
                type="from"
                initialContent={poolToken && poolToken.length > 0 ? poolToken[0] : tokens[0]}
                contentList={tokens}
                backgroundColor="bg-[#37404c]"
                prevSelected={prevSelected}
                disabled={isSubmitting}
              />
              <LineIcon />
            </div>
          )}
          <div className="flex items-center w-10/12 justify-between pr-2">
            <input
              type="number"
              step="1"
              placeholder={to ? to : 0}
              value={to}
              min={0}
              disabled={isUnstakeMode || loading || isSubmitting}
              maxLength={8}
              onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              onChange={e => {
                setPriceLoading(true);
                setTo(e.target.value);
                formulateToFrom(e.target.value, true);
                validateValues(
                  parseFloat(lp),
                  parseFloat(e.target.value),
                  typeof e.target.value === "string" ? parseFloat(e.target.value) : e.target.value,
                );
              }}
              className="text-white bg-transparent px-2 w-[70%]"
              style={{ outline: "none" }}
            />
            {parseFloat(Number(to).toFixed(1)) === 0 &&
            parseFloat(Number(from).toFixed(1)) !== 0 ? (
              <Spin indicator={<LoadingOutlined style={{ fontSize: 24, color: "grey" }} spin />} />
            ) : (
              ""
            )}
            {!isUnstakeMode && (
              <button
                type="button"
                className="flex mx-2 text-app-blue font-medium"
                onClick={() => handleMaxClick("to")}
              >
                MAX
              </button>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-end mt-4 text-sm">
          <div className="font-normal text-gray-400">
            Balance: {tokenTo?.tokenBalance ? tokenTo?.tokenBalance : "0.0"}
          </div>
        </div>
        <div className="flex w-full min-h-16 justify-between rounded-lg bg-[#37404c]">
          {tokens.length > 0 && (
            <div className="flex items-center space-x-20">
              <TokenDropdownMeta
                type="to"
                initialContent={poolToken && poolToken.length > 0 ? poolToken[1] : tokens[1]}
                contentList={tokens}
                backgroundColor="bg-[#37404c]"
                prevSelected={prevSelected}
                disabled={isSubmitting}
              />
              <LineIcon />
            </div>
          )}
          <div className="flex items-center w-10/12 justify-between pr-2">
            <input
              type="number"
              placeholder={from ? from : 0}
              value={from}
              step="1"
              min={0}
              onKeyDown={evt => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              disabled={
                isUnstakeMode || loading || parseFloat(Number(to).toFixed(1)) === 0 || isSubmitting
              }
              maxLength={8}
              onChange={e => {
                setPriceLoading(true);
                setFrom(e.target.value);
                formulateToFrom(e.target.value);

                validateValues(
                  parseFloat(balanceLp),
                  typeof e.target.value === "string" ? parseFloat(e.target.value) : e.target.value,
                  parseFloat(e.target.value),
                );
              }}
              className="text-white bg-transparent px-2 w-[70%]"
              style={{ outline: "none" }}
            />
            {priceLoading ? (
              <div>
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 24, color: "grey" }} spin />}
                />
              </div>
            ) : (
              ""
            )}
            {!isUnstakeMode && (
              <button
                type="button"
                className="flex mx-2 text-app-blue font-medium"
                onClick={() => handleMaxClick("from")}
              >
                MAX
              </button>
            )}
          </div>
        </div>
      </form>
      <div className="flex flex-col py-4 mt-6 mb-8 xs:py-8 rounded-xl bg-app-black-button">
        <div className="flex justify-center pb-5 mb-4 border-b-2 border-gray-500">
          <div className="text-xs font-medium 2xs:text-base">
            {isFilledPool ? "Prices" : "Initial prices"} and pool share
          </div>
        </div>
        <div className="flex flex-col text-lg font-medium 2xs:text-xs 2xs:justify-around 2xs:flex-row 1xs:text-base">
          <div className="flex flex-col items-center">
            <div className="flex flex-row justify-center">
              <div>{fromPerToAmount.toString() === "Infinity" ? 0 : fromPerToAmount}</div>
            </div>
            <div>
              <span title={tokenFrom?.symbol}>{getShortTokenName(tokenFrom?.symbol)}</span>{" "}
              per&nbsp;
              <span title={tokenTo.symbol}>{getShortTokenName(tokenTo.symbol)}</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row justify-center">
              <div>{toPerFromAmount.toString() === "Infinity" ? 0 : toPerFromAmount}</div>
            </div>
            <div>
              <span title={tokenTo.symbol}>{getShortTokenName(tokenTo.symbol)}</span> per&nbsp;
              <span title={tokenFrom?.symbol}>{getShortTokenName(tokenFrom?.symbol)}</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row justify-center">
              <div>{isNaN(poolShare) ? 0 : poolShare}%</div>
            </div>
            <div>Share of Pool</div>
          </div>
        </div>
      </div>
      <button
        onClick={submitForm}
        disabled={
          !!errors?.custom?.message ||
          isSubmitting ||
          loading ||
          providedLoading ||
          priceLoading ||
          (isUnstakeMode ? !lp : !to || !from)
        }
        className="p-4 h-16 mt-5 flex gap-3 justify-center items-center bg-app-blue text-lg disabled:bg-app-black-button disabled:cursor-not-allowed hover:bg-[#50D0FB]"
      >
        {isSubmitting && <Loader />}
        {errors?.custom?.message || (create ? "Create" : isUnstakeMode ? "Remove" : "Supply")}
      </button>
    </div>
  );
};

export default LiquidityMetamask;
